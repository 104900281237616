// utils
import AWS from "aws-sdk";
import i18next from 'i18next';
import { getEmail, getFamilyName, getGivenName, getStoreId, getUserId } from '../components/authentication/TempAuthService';
import axios from '../utils/axios';
import { sellGPTExecutionRole, sellGPTLambdaName, sellGPTRegion } from './secret';
import { resizeFile } from 'src/utils/common';
import { Auth } from 'aws-amplify';

const REQUEST_CONFIG = {
  headers: {
    // Add the file & variable importer above in your local code, by default
    // the file is in .gitignore to not expose the api key
    'x-api-key': process.env.REACT_APP_API_KEY,
  },
};

async function getRequestHeaders() {
  var user;
  try {
    // currentSession() checks if id token is expired and refreshes with Cognito if needed automatically
    user = await Auth.currentSession();
  } catch (err) {
    console.log(err);
    throw err;
  }

  return {
    headers: {
      'Authorization': user.getAccessToken().getJwtToken()
    }
  };
};

const API_ROOT = process.env.REACT_APP_API_ROOT + '/';

async function getReqConfWithAbort(abortController) {
  const requestHeaders = await getRequestHeaders();
  return { ...requestHeaders, signal: abortController.signal };
}

// <----------------------------------------------------Product Images----------------------------------------------------------------->
async function replaceImageUrlWithData(product, abortController) {
  return new Promise((resolve, reject) => {
    if (product.Images) {
      getImagesInAWS(product.Images, abortController)
        .then((imageUrls) => {
          const productWithImageData = { ...product, Images: imageUrls };
          resolve(productWithImageData);
        })
        .catch((e) => {
          console.log(e);
          resolve(product);
        });
    } else {
      resolve(product);
    }
  });
}

async function putImageInAWS(productCode, image) {
  const imagePayload = typeof image === Object ? image.payload : image;
  // Use first/mid/last X alphanumeric characters as the unique id for images
  const getUniqueId = () => {
    const getAlphanumSubstrFrom = (idx) => {
      let substr = idx < 0 ? imagePayload.substr(idx) : imagePayload.substr(idx, 16);
      return substr.replace(/[^A-Za-z0-9]/g, '');
    };

    // Image payload comes after metadata delimited by a comma
    const payloadStart = imagePayload.indexOf(',') + 1;
    const payloadStartSubstr = getAlphanumSubstrFrom(payloadStart);

    const payloadMid = parseInt(imagePayload.length / 2);
    const payloadMidSubstr = getAlphanumSubstrFrom(payloadMid);

    const payloadEndSubstr = getAlphanumSubstrFrom(-16);
    return payloadStartSubstr + payloadMidSubstr + payloadEndSubstr;
  };
  const imageName = getStoreId() + '-' + productCode + '-' + getUniqueId();
  const requestUrl = API_ROOT + 'images/' + imageName;
  const requestBody = { image: imagePayload };
  await axios
    .put(requestUrl, requestBody, { ...REQUEST_CONFIG, 'Content-Type': image.type })
    .then((_) => {
      return requestUrl;
    })
    .catch((e) => {
      console.log(e);
    });
  // TODO: Remove the below after investigating why PUT images still works
  // despite returning error (with code 200[???])
  return requestUrl;
}

export async function getStoreImage(storeID) {
  const BUCKET_ROOT = 'https://store-images-sp.s3.amazonaws.com/';
  const requestURL = BUCKET_ROOT + storeID;
  return await axios.get(requestURL, REQUEST_CONFIG);
}

export async function putImagesInAWS(productCode, images) {
  const imageUrlPromises = images.map((image) => {
    return new Promise((resolve, reject) => {
      putImageInAWS(productCode, image)
        .then((imageUrl) => resolve(imageUrl))
        .catch((e) => reject(e));
    });
  });
  const imageUrls = await Promise.all(imageUrlPromises);
  return imageUrls;
}

export async function getImageInAWS(imageUrl, abortController) {
  const reqConf = await getReqConfWithAbort(abortController);
  return await axios.get(imageUrl, { ...reqConf, 'Content-Type': 'image/jpeg', 'Cache-Control': 'no-cache' });
}

export async function getImagesInAWS(imageUrls, abortController) {
  const imageDataPromises = imageUrls.map((imageUrl) => {
    return new Promise((resolve, reject) => {
      getImageInAWS(imageUrl, abortController)
        .then((response) => {
          resolve(response.data.image);
        })
        .catch((e) => {
          resolve(imageUrl);
        });
    });
  });
  const imageData = await Promise.all(imageDataPromises);
  return imageData;
}

// <----------------------------------------------------Products----------------------------------------------------------------->

export async function getProductsFromAWS(abortController) {
  const reqConf = await getReqConfWithAbort(abortController);
  const fetchProductsPromise = new Promise((resolve, reject) => {
    const requestUrl = API_ROOT + getStoreId() + '/products';
    axios
      .get(requestUrl, reqConf)
      .then(async (response) => {
        if (response.data.body === 'Cannot get products for a seller store') {
          resolve([]);
        } else if (response.data.response === undefined) {
          resolve([]);
        } else {
          // map products' images to their base64 encoding stored in S3
          const productsPromises = response.data.response.map((product) =>
            replaceImageUrlWithData(product, abortController),
          );

          const products = await Promise.all(productsPromises);
          resolve(products);
        }
      })
      .catch((e) => {
        console.log(e);
        resolve([]);
      });
  });

  const fetchedProducts = await Promise.all([fetchProductsPromise]);
  return fetchedProducts[0];
}

export async function getProductFromAWS(abortController, productId) {
  const reqConf = await getReqConfWithAbort(abortController);
  const fetchProductsPromise = new Promise((resolve, reject) => {
    const requestUrl = API_ROOT + getStoreId() + '/products' + productId + '/single';
    axios
      .get(requestUrl, reqConf)
      .then(async (response) => {
        if (response.data.body === 'Cannot get products for a seller store') {
          resolve([]);
        } else if (response.data.response === undefined) {
          resolve([]);
        } else {
          // console.log(response.data)
          // map products' images to their base64 encoding stored in S3
          const productsPromises = response.data.response.map((product) =>
            replaceImageUrlWithData(product, abortController),
          );

          const products = await Promise.all(productsPromises);
          resolve(products);
        }
      })
      .catch((e) => {
        console.log(e);
        resolve([]);
      });
  });

  const fetchedProducts = await Promise.all([fetchProductsPromise]);
  return fetchedProducts[0];
}

export async function getProductsNoImageFromAWS(abortController) {
  const reqConf = await getReqConfWithAbort(abortController);
  const fetchProductsPromise = new Promise((resolve, reject) => {
    const requestUrl = API_ROOT + getStoreId() + '/products/no-image';
    axios
      .get(requestUrl, reqConf)
      .then(async (response) => {
        if (response.data.body === 'Cannot get products for a seller store') {
          resolve([]);
        } else if (response.data.response === undefined) {
          resolve([]);
        } else {
          resolve(response.data.response);
        }
      })
      .catch((e) => {
        console.log(e);
        resolve([]);
      });
  });

  const fetchedProducts = await Promise.all([fetchProductsPromise]);
  return fetchedProducts[0];
}

export async function getCombinedProductsFromAWS(abortController) {
  const productsNoImage = await getProductsFromAWS(abortController);
  const manufacturedProducts = await getManufacturedProductsFromAWS(abortController);
  return [...productsNoImage, ...manufacturedProducts];
}

export async function getPostProductToAWS(product, imageUrls) {
  const requestUrl = API_ROOT + getStoreId() + '/products';

  product.images = imageUrls;
  const requestBody = JSON.stringify({
    storeID: getStoreId(),
    products: [
      {
        // AWS lambda: add-products2
        productName: product.productName,
        productCode: product.productCode,
        price: product.price,
        images: imageUrls,
        taxIncluded: product.taxIncluded,
        color: product.color,
        basicColor: product.basicColor,
        colorClass: product.colorClass,
        material: product.material,
        density: product.density,
        standard: product.standard,
        weight: product.weight,
        grade: product.grade,
        fabricClass: product.fabricClass,
        warehouse: product.warehouse,
        use: product.use,
        raw: product.raw,
        yarnDenier: product.yarnDenier,
        yarnBale: product.yarnBale,
        manufacturerName: product.manufacturerName,
        manufacturerPrice: product.manufacturerPrice,
        manufacturerCode: product.manufacturerCode,
        invoiceProductName: product.invoiceProductName,
      },
    ],
  });
  const requestHeaders = await getRequestHeaders();
  return await axios.post(requestUrl, requestBody, requestHeaders);
}

export async function deleteProductToAWS(id) {
  const requestUrl = API_ROOT + getStoreId() + '/products/' + id;
  const requestHeaders = await getRequestHeaders();
  return await axios.delete(requestUrl, requestHeaders);
}

// <----------------------------------------------------Raw Material----------------------------------------------------------------->
export async function getRawMaterialsFromAWS(abortController) {
  const reqConf = await getReqConfWithAbort(abortController);
  const requestUrl = API_ROOT + getStoreId() + '/raw-materials';
  const response = await axios.get(requestUrl, reqConf);
  const imageMappedResponse = response.data.map((rawMaterial) => replaceImageUrlWithData(rawMaterial, abortController));
  return response.data;
}

export const getRawMaterialFromAWS = async (id) => {
  const requestUrl = API_ROOT + getStoreId() + '/raw-materials/' + id;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(requestUrl, requestHeaders);
  return response.data;
};

export const updateRawMaterialToAWS = async (rawMaterial, imageUrls) => {
  const requestUrl = API_ROOT + getStoreId() + '/raw-materials/' + rawMaterial.id;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.put(
    requestUrl,
    {
      material: rawMaterial,
    },
    requestHeaders,
  );
  return response.data;
};

export const deleteRawMaterialToAWS = async (id) => {
  const requestUrl = API_ROOT + getStoreId() + '/raw-materials/' + id;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.delete(requestUrl, requestHeaders);
  return response.data;
};

export const deleteBatchRawMaterialToAWS = async (ids) => {
  const requestUrl = API_ROOT + getStoreId() + '/raw-materials';
  const requestHeaders = await getRequestHeaders();
  const response = await axios.delete(requestUrl, {
    ...requestHeaders,
    data: {
      materialIds: ids,
    },
  });
  return response.data;
};

export const createRawMaterialToAWS = async (rawMaterial, imageUrls) => {
  const requestUrl = API_ROOT + getStoreId() + '/raw-materials';
  const requestHeaders = await getRequestHeaders();
  const response = await axios.post(
    requestUrl,
    {
      material: rawMaterial,
    },
    requestHeaders,
  );
  return response.data;
};

// <----------------------------------------------------Manufactured Product----------------------------------------------------------------->
export async function getManufacturedProductsFromAWS(abortController) {
  const requestUrl = API_ROOT + getStoreId() + '/manufactured-products';
  const reqConf = await getReqConfWithAbort(abortController);
  const response = await axios.get(requestUrl, reqConf);
  const imageMappedResponse = response.data.map((manufacturedProduct) =>
    replaceImageUrlWithData(manufacturedProduct, abortController),
  );

  return response.data;
}

export const getManufacturedProductFromAWS = async (id) => {
  const requestUrl = API_ROOT + getStoreId() + '/manufactured-products/' + id;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(requestUrl, requestHeaders);
  return response.data;
};

export const updateManufacturedProductToAWS = async (manufacturedProduct, imageUrls) => {
  const requestUrl = API_ROOT + getStoreId() + '/manufactured-products';
  const requestHeaders = await getRequestHeaders();
  const response = await axios.patch(
    requestUrl,
    {
      product: manufacturedProduct,
    },
    requestHeaders,
  );
  return response.data;
};

export const deleteManufacturedProductToAWS = async (id) => {
  const requestUrl = API_ROOT + getStoreId() + '/manufactured-products/' + id;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.delete(requestUrl, requestHeaders);
  return response.data;
};

export const deleteBatchManufacturedProductToAWS = async (ids) => {
  const requestUrl = API_ROOT + getStoreId() + '/manufactured-products';
  const requestHeaders = await getRequestHeaders();
  const response = await axios.delete(requestUrl, {
    ...requestHeaders,
    data: {
      productIds: ids,
    },
  });
  return response.data;
};

export const createManufacturedProductToAWS = async (manufacturedProduct, imageUrls) => {
  const requestUrl = API_ROOT + getStoreId() + '/manufactured-products';
  const requestHeaders = await getRequestHeaders();
  const response = await axios.post(
    requestUrl,
    {
      product: manufacturedProduct,
    },
    requestHeaders,
  );
  return response.data;
};

// <----------------------------------------------------Current Tutorial Statuses----------------------------------------------------------------->
export async function getTutorialStatusFromAWS(abortController) {
  try {
    const storeId = getStoreId();
    const userId = getUserId();
    if (!userId) throw new Error(i18next.t('error.userIdNotFound'));
    const requestUrl = API_ROOT + storeId + '/tutorial-status' + '?userId=' + userId;
    const reqConf = await getReqConfWithAbort(abortController);
    const response = await axios.get(requestUrl, reqConf);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export async function patchIndividualTutorialStatusFromAWS(tutorialKeyName, value) {
  try {
    const userId = getUserId();
    if (!userId) throw new Error(i18next.t('error.userIdNotFound'));
    const requestUrl = API_ROOT + getStoreId() + '/tutorial-status' + '?userId=' + userId;
    const requestBody = {
      storeID: getStoreId(),
      key: tutorialKeyName,
      value: value,
    };
    const requestHeaders = await getRequestHeaders();
    return await axios.patch(requestUrl, requestBody, requestHeaders);
  } catch (error) {
    console.log(error);
    throw error;
  }
}

// <----------------------------------------------------Sell GPT----------------------------------------------------------------->
// Get request results upon load from previous chat histories
export async function getGptQueryFromAWS(abortController) {
  try {
    const requestUrl = API_ROOT + getStoreId() + '/sell-gpt';
    const reqConf = await getReqConfWithAbort(abortController);
    const response = await axios.get(requestUrl, reqConf);
    return response.data.body;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

// This method decodes the encrypted values sent in from the environmental variables
function decodeValue(encodedValue) {
  const encodedValueString = encodedValue.toString();
  try {
    const decodedString = Buffer.from(encodedValueString, 'base64').toString('utf-8');
    return decodedString;
  } catch (error) {
    console.error("Error decoding Base64:", error);
    throw error;
  }
}

// STS assumes the role entitled sellGPTExecutionRole to limit permissions for the sellGPT post method
const getCrossAccountCredentials = async () => {
  const sts = new AWS.STS();
  return new Promise((resolve, reject) => {
    const timestamp = (new Date()).getTime();
    const params = {
      RoleArn: sellGPTExecutionRole,
      RoleSessionName: `sellGPT-call${timestamp}`
    };
    sts.assumeRole(params, (err, data) => {
      if (err) reject(err);
      else {
        resolve({
          accessKeyId: data.Credentials.AccessKeyId,
          secretAccessKey: data.Credentials.SecretAccessKey,
          sessionToken: data.Credentials.SessionToken,
        });
      }
    });
  });
}

// Posting method for sellGPT for LLM responses
export async function postSellGptQueryToAWS(query) {
  try {
    const requestHeaders = await getRequestHeaders();
    // Getting IAM credentials for the sellGPT user that has limited permissions
    const requestUrl = API_ROOT + getStoreId() + '/sell-gpt/assume-role';
    const response = await axios.get(requestUrl, requestHeaders);
    
    // Attempt to decode ID and Key, short-circuiting on failure
    const decodedId = decodeValue(response.data.encoded_id);
    const decodedKey = decodeValue(response.data.encoded_key);

    // Configuring AWS authentication to the limited permissions SellGPT user
    AWS.config.update({
      region: sellGPTRegion,
      accessKeyId: decodedId,
      secretAccessKey: decodedKey,
    });

    // Assuming STS role for limited functionality SellGPT
    const accessparams = await getCrossAccountCredentials();

    // After the assumed role is successful, invoke Lambda
    const lambda = new AWS.Lambda(accessparams);
    const functionName = sellGPTLambdaName; // Replace with your Lambda function name
    const invocationParams = {
      FunctionName: functionName,
      Payload: JSON.stringify({
        storeId: getStoreId(),
        query: query,
      }),
    };

    const responseFromLambda = await lambda.invoke(invocationParams).promise();
    // Process and handle the response from the Lambda function here
    return responseFromLambda;
  } catch (error) {
    // Handle any errors that occurred during the process
    console.error(error, error.stack);
    throw error;
  }
}

// Changing Databases for primary and secondary data banks for queries
export async function patchSellGptDatabaseFromAWS(primaryDatabase, secondaryDatabase) {
  const requestUrl = API_ROOT + getStoreId() + '/sell-gpt';
  const requestBody = JSON.stringify({
    storeId: getStoreId(),
    primaryDatabaseSelection: primaryDatabase,
    secondaryDatabaseSelection: secondaryDatabase,
  });
  const requestHeaders = await getRequestHeaders();
  return await axios.patch(requestUrl, requestBody, requestHeaders);
}

// <----------------------------------------------------Procurement Feature----------------------------------------------------------------->
export async function getProcurementOrdersFromAWS(abortController, procurementOrderId) {
  try {
    let requestUrl = API_ROOT + getStoreId() + '/procurement';
    if (procurementOrderId) requestUrl += ("/"+ procurementOrderId);
    const reqConf = await getReqConfWithAbort(abortController);
    return await axios.get(requestUrl, reqConf);
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function postProcurementOrderToAWS(procurementType, procurementOrder) {
  try {
    const requestUrl = API_ROOT + getStoreId() + '/procurement';
    const requestBody = JSON.stringify({
      storeId: getStoreId(),
      procurementType: procurementType,
      procurementOrder: procurementOrder,
    });
    const requestHeaders = await getRequestHeaders();
    return await axios.post(requestUrl, requestBody, requestHeaders);
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function updateProcurementOrdersToAWS(abortController, procurementType, procurementId, updateValues) {
  try {
    const requestUrl = API_ROOT + getStoreId() + '/procurement';
    const reqConf = await getReqConfWithAbort(abortController);
    return await axios.patch(requestUrl, JSON.stringify({
      "procurementOrderId":procurementId,
      "procurementType":procurementType,
      "updateValues":updateValues,
      "storeID":getStoreId()
    }), reqConf);
  } catch (error) {
    console.error(error);
    throw error;
  }
}

// <----------------------------------------------------Offline Orders----------------------------------------------------------------->

export async function getOfflineOrderFromAWS(abortController, id) {
  const reqConf = await getReqConfWithAbort(abortController);
  const fetchOfflineOrderPromise = new Promise((resolve, reject) => {
    const requestUrl = API_ROOT + getStoreId() + '/offline-orders' + id;
    axios
      .get(requestUrl, reqConf)
      .then(async (response) => {
        resolve(response.data.response);
      })
      .catch((e) => {
        console.log(e);
        resolve([]);
      });
  });

  const fetchedOfflineOrder = await Promise.all([fetchOfflineOrderPromise]);
  return fetchedOfflineOrder[0];
}

export async function getOfflineOrdersFromAWS(abortController) {
  const reqConf = await getReqConfWithAbort(abortController);
  const fetchOfflineOrdersPromise = new Promise((resolve, reject) => {
    const requestUrl = API_ROOT + getStoreId() + '/offline-orders';
    axios
      .get(requestUrl, reqConf)
      .then(async (response) => {
        resolve(response.data.response);
      })
      .catch((e) => {
        console.log(e);
        resolve([]);
      });
  });

  const fetchedOfflineOrders = await Promise.all([fetchOfflineOrdersPromise]);
  return fetchedOfflineOrders[0];
}

export async function getOfflineOrdersConfigFromAWS(abortController) {
  const reqConf = await getReqConfWithAbort(abortController);
  const fetchOfflineOrdersConfigPromise = new Promise((resolve, reject) => {
    const requestUrl = API_ROOT + getStoreId() + '/fields-config';
    axios
      .get(requestUrl, reqConf)
      .then(async (response) => {
        resolve(response);
      })
      .catch((e) => {
        console.log(e);
        resolve([]);
      });
  });

  const fetchedOfflineOrdersConfig = await Promise.all([fetchOfflineOrdersConfigPromise]);
  return fetchedOfflineOrdersConfig[0];
}

export async function getInventoryConfigFromAWS(abortController) {
  const reqConf = await getReqConfWithAbort(abortController);
  const fetchOfflineOrdersConfigPromise = new Promise((resolve, reject) => {
    const requestUrl = API_ROOT + getStoreId() + '/inventory-config';
    // console.log("getOfflineOrdersConfigFromAWS", requestUrl)
    axios
      .get(requestUrl, reqConf)
      .then(async (response) => {
        resolve(response);
      })
      .catch((e) => {
        console.log(e);
        resolve([]);
      });
  });

  const fetchedOfflineOrdersConfig = await Promise.all([fetchOfflineOrdersConfigPromise]);
  return fetchedOfflineOrdersConfig[0];
}

export async function getRawMaterialsConfigFromAWS(abortController) {
  const reqConf = await getReqConfWithAbort(abortController);
  const fetchOfflineOrdersConfigPromise = new Promise((resolve, reject) => {
    const requestUrl = API_ROOT + getStoreId() + '/inventory-config/raw-materials';
    axios
      .get(requestUrl, reqConf)
      .then(async (response) => {
        resolve(response);
      })
      .catch((e) => {
        console.log(e);
        resolve([]);
      });
  });

  const fetchedOfflineOrdersConfig = await Promise.all([fetchOfflineOrdersConfigPromise]);
  return fetchedOfflineOrdersConfig[0];
}

export async function getManufacturedProductsConfigFromAWS(abortController) {
  const reqConf = await getReqConfWithAbort(abortController);
  const fetchManufacturedProductsConfigPromise = new Promise((resolve, reject) => {
    const requestUrl = API_ROOT + getStoreId() + '/inventory-config/manufactured-products';
    axios
      .get(requestUrl, reqConf)
      .then(async (response) => {
        resolve(response);
      })
      .catch((e) => {
        console.log(e);
        resolve([]);
      });
  });

  const fetchedManufacturedProductsConfig = await Promise.all([fetchManufacturedProductsConfigPromise]);
  return fetchedManufacturedProductsConfig[0];
}

export async function getOfflinePopulationFromAWS(abortController) {
  const reqConf = await getReqConfWithAbort(abortController);
  const fetchOfflinePopulationPromise = new Promise((resolve, reject) => {
    const requestUrl = API_ROOT + getStoreId() + '/offline-population';
    axios
      .get(requestUrl, reqConf)
      .then(async (response) => {
        resolve(response);
      })
      .catch((e) => {
        console.log(e);
        resolve([]);
      });
  });

  const fetchedOfflinePopulationConfig = await Promise.all([fetchOfflinePopulationPromise]);
  return fetchedOfflinePopulationConfig[0];
}

export async function getPostOfflineOrdersToAWS(orders, orderType) {
  const requestUrl = API_ROOT + getStoreId() + '/online-orders';
  const requestBody = JSON.stringify({
    storeID: getStoreId(),
    userGrid : {
      email: getEmail(),
      given_name: getGivenName(),
      family_name: getFamilyName(),
    },
    orderType: orderType,
    orders: orders,
  });
  const requestHeaders = await getRequestHeaders();
  return await axios.post(requestUrl, requestBody, requestHeaders);
}

export async function updateOfflineOrdersToAWS(orders) {
  const requestUrl = API_ROOT + getStoreId() + '/offline-orders';
  const requestBody = JSON.stringify({
    storeID: getStoreId(),
    orders: orders,
  });
  const requestHeaders = await getRequestHeaders();
  return await axios.patch(requestUrl, requestBody, requestHeaders);
}

// <---------------------------------------------------- Unlock Excel ----------------------------------------------------------------->
export async function unlockExcel(file, password) {
  const requestUrl = API_ROOT + 'unlock-excel';

  const formData = new FormData();
  formData.append('password', password);
  formData.append('file', file);

  const _REQUEST_CONFIG = {
    headers: {
      'Content-Type': 'multipart/form-data',
      ...REQUEST_CONFIG.headers,
    },
  };
  return await axios.post(requestUrl, formData, _REQUEST_CONFIG);
}

// <---------------------------------------------------- Fields Configuration----------------------------------------------------------------->
export async function postFieldsConfigToAWS(fields) {
  const requestUrl = API_ROOT + getStoreId() + '/fields-config';
  const requestBody = JSON.stringify({
    fields: fields,
  });
  const requestHeaders = await getRequestHeaders();
  return await axios.post(requestUrl, requestBody, requestHeaders);
}

export async function postMappingFieldsConfig(userFieldInput, sellpointInputRequirements) {
  const requestUrl = API_ROOT + getStoreId() + '/fields-config/fields-mapping';
  const requestHeaders = await getRequestHeaders();
  const requestBody = JSON.stringify({
    userFieldInput: userFieldInput,
    sellpointInputRequirements: sellpointInputRequirements,
  });
  return await axios.post(requestUrl, requestBody, requestHeaders);
}
// <---------------------------------------------------- Inventory Raw Material Configuration----------------------------------------------------------------->
export async function postFieldsRawMaterialsConfigToAWS(fields) {
  const requestUrl = API_ROOT + getStoreId() + '/inventory-config/raw-materials';
  const requestBody = JSON.stringify({
    fields: fields,
  });
  const requestHeaders = await getRequestHeaders();
  return await axios.post(requestUrl, requestBody, requestHeaders);
}

export async function postFieldsManufacturedProductsConfigToAWS(fields) {
  const requestUrl = API_ROOT + getStoreId() + '/inventory-config/manufactured-products';
  const requestBody = JSON.stringify({
    fields: fields,
  });
  const requestHeaders = await getRequestHeaders();
  return await axios.post(requestUrl, requestBody, requestHeaders);
}

// <----------------------------------------------------Customer Account Directory----------------------------------------------------------------->
/**
 * Gets customer from the API.
 *
 * @param {Controller} abortController controller that aborts the http request if needed
 * @param {String} id the CustomerID of the customer eg. "8d8783ac-3c74-4a96-a589-e7106fb71e11"
 * @return {Promise} the promise object representing the status of the request.
 */
export async function getCustomerFromAWS(abortController, id) {
  const reqConf = await getReqConfWithAbort(abortController);
  const fetchCustomerPromise = new Promise((resolve, reject) => {
    const requestUrl = API_ROOT + getStoreId() + '/customer/' + id;
    axios
      .post(requestUrl, reqConf)
      .then(async (response) => {
        resolve(response.data.response);
      })
      .catch((e) => {
        console.log(e);
        resolve([]);
      });
  });

  const fetchedCustomer = await Promise.all([fetchCustomerPromise]);
  return fetchedCustomer[0];
}

export async function getCustomersFromAWS(abortController) {
  const reqConf = await getReqConfWithAbort(abortController);
  const fetchCustomersPromise = new Promise((resolve, reject) => {
    const requestUrl = API_ROOT + getStoreId() + '/customers';
    axios
      .get(requestUrl, reqConf)
      .then(async (response) => {
        resolve(response.data);
      })
      .catch((e) => {
        console.log(e);
        resolve([]);
      });
  });

  const fetchedCustomers = await Promise.all([fetchCustomersPromise]);
  return fetchedCustomers[0];
}

export async function postCustomerToAWS(customer) {
  const requestUrl = API_ROOT + getStoreId() + '/customers';
  const requestBody = JSON.stringify({
    storeID: getStoreId(),
    customer: customer,
  });
  const requestHeaders = await getRequestHeaders();
  return await axios.post(requestUrl, requestBody, requestHeaders);
}

export async function updateCustomersToAWS(customers) {
  const requestUrl = API_ROOT + getStoreId() + '/customers';
  const requestBody = {
    storeID: getStoreId(),
    customers: customers,
  };
  const requestHeaders = await getRequestHeaders();
  return await axios.patch(requestUrl, requestBody, requestHeaders);
}

export async function deleteCustomersFromAWS(customers) {
  const requestUrl = API_ROOT + getStoreId() + '/customers';
  const requestBody = JSON.stringify({
    storeID: getStoreId(),
    customers: customers,
  });
  const requestHeaders = await getRequestHeaders();
  return await axios({
    method: 'DELETE',
    url: requestUrl,
    data: requestBody,
    headers: requestHeaders.headers,
  });
}

// <----------------------------------------------------Invoices----------------------------------------------------------------->
export async function getInvoicesFromAWS(abortController) {
  const reqConf = await getReqConfWithAbort(abortController);
  const fetchInvoicesPromise = new Promise((resolve, reject) => {
    const requestUrl = API_ROOT + getStoreId() + '/invoices';
    axios
      .get(requestUrl, reqConf)
      .then(async (response) => {
        resolve(response.data.response);
      })
      .catch((e) => {
        console.log(e);
        resolve([]);
      });
  });

  const fetchedInvoices = await Promise.all([fetchInvoicesPromise]);
  return fetchedInvoices[0];
}

export async function getInvoiceFromAWS(abortController, id) {
  const reqConf = await getReqConfWithAbort(abortController);
  const fetchInvoicePromise = new Promise((resolve, reject) => {
    const requestUrl = API_ROOT + getStoreId() + '/invoices/' + id;
    axios
      .get(requestUrl, reqConf)
      .then(async (response) => {
        resolve(response.data.response);
      })
      .catch((e) => {
        console.log(e);
        resolve([]);
      });
  });

  const fetchedInvoice = await Promise.all([fetchInvoicePromise]);
  return fetchedInvoice[0];
}

export async function getBatchInvoicesFromAWS(abortController, batchId) {
  const reqConf = await getReqConfWithAbort(abortController);
  const fetchInvoicePromise = new Promise((resolve, reject) => {
    const requestUrl = API_ROOT + getStoreId() + '/invoices-batch?batchId=' + batchId;
    axios
      .get(requestUrl, reqConf)
      .then(async (response) => {
        resolve(response.data);
      })
      .catch((e) => {
        console.log(e);
        resolve([]);
      });
  });

  const fetchedInvoice = await Promise.all([fetchInvoicePromise]);
  return fetchedInvoice[0];
}

export async function deleteInvoiceFromAWS(abortController, id) {
  const reqConf = await getReqConfWithAbort(abortController);
  const requestUrl = API_ROOT + getStoreId() + '/invoices/' + id;
  return await axios.delete(requestUrl, reqConf);
}

export async function getPostInvoiceToAWS(invoice) {
  const requestUrl = API_ROOT + getStoreId() + '/invoices';
  const requestBody = JSON.stringify({
    storeID: getStoreId(),
    userGrid : {
      email: getEmail(),
      given_name: getGivenName(),
      family_name: getFamilyName(),
    },
    invoice: invoice,
  });
  const requestHeaders = await getRequestHeaders();
  return await axios.post(requestUrl, requestBody, requestHeaders);
}

export async function updateInvoiceToAWS(invoice) {
  const requestUrl = API_ROOT + getStoreId() + '/invoices';
  const requestBody = JSON.stringify({
    storeID: getStoreId(),
    invoice: invoice,
  });
  const requestHeaders = await getRequestHeaders();
  return await axios.patch(requestUrl, requestBody, requestHeaders);
}

// https://f8kus65p30.execute-api.us-east-1.amazonaws.com/dev/073760c2-84c0-4377-9c10-b8d86f36bd9e/invoices-batch
export async function updateBatchInvoicesToAWS(invoices) {
  const requestUrl = API_ROOT + getStoreId() + '/invoices-batch';
  const requestBody = JSON.stringify({
    storeID: getStoreId(),
    invoices,
  });
  const requestHeaders = await getRequestHeaders();
  return await axios.put(requestUrl, requestBody, requestHeaders);
}

// <----------------------------------------------------Orders----------------------------------------------------------------->
export async function getPostOrdersToAWS(orders) {
  const requestUrl = API_ROOT + getStoreId() + '/orders';
  const requestBody = JSON.stringify({
    storeID: getStoreId(),
    orders: orders,
  });
  const requestHeaders = await getRequestHeaders();
  return await axios.post(requestUrl, requestBody, requestHeaders);
}

// <----------------------------------------------------Account Login/Reg----------------------------------------------------------------->
export async function createStoreInAWS(formValues) {
  const requestUrl = API_ROOT + 'create-store';
  const requestBody = JSON.stringify({
    storeName: formValues.storeName,
    storeType: formValues.accountType,
    email: formValues.email,
    storeId: formValues.storeId,
    roleId: formValues.roleId,
    inviteId: formValues.inviteId,
    firstName: formValues.firstName,
    lastName: formValues.lastName,
    userId: formValues.userId,
  });
  return await axios.post(requestUrl, requestBody, REQUEST_CONFIG);
}

export async function authenticateWithAWS(formValues) {
  const requestUrl = API_ROOT + 'login';
  const requestBody = JSON.stringify({
    email: formValues.email,
    pw: formValues.password,
  });
  return await axios.post(requestUrl, requestBody, REQUEST_CONFIG);
}

// <----------------------------------------------------Contact Us----------------------------------------------------------------->
/**
 * Sends an automated email to the current support email.
 * The current support email is configured to `support@sellpoint.ai`.
 * To change this, edit the supportEmail variable in the sendEmail AWS Lambda.
 *
 * @param {String} messageObj.email The email of the user
 * @param {String} messageObj.message The message the user wants to send to
 * the support email.
 */
export async function sendEmail(message) {
  const requestUrl = API_ROOT + 'send-email';
  const requestBody = JSON.stringify({
    contactEmail: getEmail(),
    contactMessage: message,
  });
  return await axios.post(requestUrl, requestBody);
}

// <----------------------------------------------------Account Settings----------------------------------------------------------------->
/**
 * Makes a GET request for store details to the serverless API.
 *
 * @param {String} storeId The ID of the store for which details are to be
 * fetched.
 */
export async function getStoreDetails(storeId, email) {
  const requestUrl = API_ROOT + storeId + '/store-details';
  const requestHeaders = await getRequestHeaders();
  return await axios.get(requestUrl, requestHeaders);
}

export async function getUserPermissions(storeId, email) {
  const requestUrl = API_ROOT + storeId + '/user-permissions';
  const requestHeaders = await getRequestHeaders();
  const config = { params: { email }, ...requestHeaders};
  return await axios.get(requestUrl, config);
}

/**
 * Posts an edit store details request to the serverless API.
 *
 * @param {String} detailsObj.storeName Store's  name
 * @param {String} detailsObj.storePhone Store's phone no.
 * @param {String} detailsObj.storeAddress Store's address
 * @param {String} detailsObj.storeCountry Store's country
 * @param {String} detailsObj.storeState Store's state
 * @param {String} detailsObj.storeZip Store's zip
 * @param {String} detailsObj.storeName Store's name
 * @param {String} detailsObj.StoreId The ID of the store for which
 * the details are to be changed
 */
export async function editStoreDetails(detailsObj) {
  const storeId = getStoreId();
  const requestUrl = API_ROOT + storeId + '/store-details';
  const requestBody = JSON.stringify(detailsObj);
  let resizedFile;
  if (!storeId) throw new Error('store id is required');
  if (detailsObj.photoURL) {
    resizedFile = await resizeFile(detailsObj.photoURL);
    await uploadFileToS3({ file: resizedFile });
  }
  const requestHeaders = await getRequestHeaders();
  return await axios.post(requestUrl, requestBody, requestHeaders);
}
/**
 * Posts an edit password request to the serverless API.
 *
 * @param {String} passwordObject.oldPassword User's old password inputted
 * @param {String} passwordObject.password User's new password inputted
 * @param {String} passwordObject.StoreId The ID of the store for which
 * the password is to be changed
 */
export async function editPassword(passwordObject) {
  const requestUrl = API_ROOT + 'edit-password';
  const requestBody = JSON.stringify(passwordObject);
  return await axios.post(requestUrl, requestBody, REQUEST_CONFIG);
}

// <----------------------------------------------------Online Orders----------------------------------------------------------------->
/**
 * Posts online orders to the serverless API.
 *
 * @param {Map} onlineOrders A map of orders to upload
 * @param {String} storeID The ID of the store for which orders are to be
 * uploaded
 * @return {Promise} the promise object representing the status of the request.
 */

// <----------------------------------------------------Online Orders----------------------------------------------------------------->
export async function getOnlineOrderFromAWS(abortController, id) {
  const reqConf = await getReqConfWithAbort(abortController);
  const fetchOnlineOrderPromise = new Promise((resolve, reject) => {
    const requestUrl = API_ROOT + getStoreId() + '/online-orders/' + id;
    axios
      .get(requestUrl, reqConf)
      .then(async (response) => {
        // console.log(response);
        resolve(response.data.response);
      })
      .catch((e) => {
        console.log(e);
        resolve([]);
      });
  });

  const fetchedOnlineOrder = await Promise.all([fetchOnlineOrderPromise]);
  return fetchedOnlineOrder[0];
}

export async function getOnlineOrdersFromAWS(abortController) {
  const reqConf = await getReqConfWithAbort(abortController);
  const fetchOnlineOrdersPromise = new Promise((resolve, reject) => {
    const requestUrl = API_ROOT + getStoreId() + '/online-orders';
    axios
      .get(requestUrl, reqConf)
      .then(async (response) => {
        resolve(response.data.response);
      })
      .catch((e) => {
        console.log(e);
        resolve([]);
      });
  });

  const fetchedOnlineOrders = await Promise.all([fetchOnlineOrdersPromise]);
  return fetchedOnlineOrders[0];
}

export async function postOnlineOrdersToAWS(onlineOrders, orderType, storeID) {
  const requestUrl = API_ROOT + getStoreId() + '/online-orders';
  const requestBody = JSON.stringify({
    storeID: storeID,
    orderType: orderType,
    orders: onlineOrders,
  });
  const requestHeaders = await getRequestHeaders();
  return await axios.post(requestUrl, requestBody, requestHeaders);
}

// Using online-orders PATCH API atm but the lambda handles all online/offline
export async function updateOrdersToAWS(orders, storeID) {
  const requestUrl = API_ROOT + getStoreId() + '/online-orders';
  const requestBody = JSON.stringify({
    storeID: storeID,
    orders: orders,
  });
  const requestHeaders = await getRequestHeaders();
  return await axios.patch(requestUrl, requestBody, requestHeaders);
}

// <----------------------------------------------------Account Utility Methods----------------------------------------------------------------->
/**
 * Makes an initial forgot password request to the serverless API.
 *
 * @param {String} email The email of the user
 */
export async function postForgotPassword(email) {
  const requestUrl = API_ROOT + 'forgot-pswd';
  const requestBody = JSON.stringify({
    email: email,
  });
  return await axios.post(requestUrl, requestBody, REQUEST_CONFIG);
}

export async function getNaverArticles(keywords) {
  const requestUrl = API_ROOT + 'buzz-words-naver';
  const requestBody = JSON.stringify({
    dataType: 'articles',
    keywords: keywords,
  });
  return await axios.post(requestUrl, requestBody, { REQUEST_CONFIG });
}

export async function getNaverCafeArticles(keywords) {
  const requestUrl = API_ROOT + 'buzz-words-naver';
  const requestBody = JSON.stringify({
    dataType: 'cafe',
    keywords: keywords,
  });
  return await axios.post(requestUrl, requestBody, { REQUEST_CONFIG });
}

export async function getNaverBlogs(keywords) {
  const requestUrl = API_ROOT + 'buzz-words-naver';
  const requestBody = JSON.stringify({
    dataType: 'blogs',
    keywords: keywords,
  });
  return await axios.post(requestUrl, requestBody, { REQUEST_CONFIG });
}

export async function getNaverProductBrands(keywords) {
  const requestUrl = API_ROOT + 'buzz-words-naver';
  const requestBody = JSON.stringify({
    dataType: 'brands',
    keywords: keywords,
  });
  return await axios.post(requestUrl, requestBody, { REQUEST_CONFIG });
}

export async function getGoogleTrends(datatype, keywords) {
  const requestUrl = API_ROOT + 'google-trends';
  const requestBody = JSON.stringify({
    datatype: datatype,
    keywords: keywords,
  });
  return await axios.post(requestUrl, requestBody, { REQUEST_CONFIG });
}

export async function getInstagramTags(keywords) {
  const requestUrl = API_ROOT + 'instagram-hashtags';
  const requestBody = JSON.stringify({
    keywords: keywords,
  });
  return await axios.post(requestUrl, requestBody, { REQUEST_CONFIG });
}

/**
 * Verifies a forgot password request.
 *
 * @param {String} token The token of this forgot password session
 * @param {String} pin The verification pin
 */
export async function verifyPin(token, pin) {
  const requestUrl = API_ROOT + 'verify-forgot-pswd';
  const requestBody = JSON.stringify({
    token: token,
    pin: pin,
  });
  return await axios.post(requestUrl, requestBody, REQUEST_CONFIG);
}

/**
 * Sets a new password, given a password and storeID.
 *
 * @param {String} storeID The storeID associated with the account
 * @param {String} password The new password
 */
export async function setNewPassword(passwordObj) {
  const requestUrl = API_ROOT + 'forgot-pswd-edit';
  return await axios.post(requestUrl, passwordObj, REQUEST_CONFIG);
}

/**
 * Posts a payment to the store.
 * @param {Object} paymentObj An object storing payment details.
 */
export async function postPayment(paymentObj) {
  const requestUrl = API_ROOT + 'payment';
  const requestBody = JSON.stringify(paymentObj);
  return await axios.post(requestUrl, requestBody, REQUEST_CONFIG);
}

/**
 * Gets a payment for a store.
 * @param {String} storeID ID of the store
 * @param {String} storeType type of the store
 
 */
export async function getPayments() {
  const requestUrl = API_ROOT + getStoreId() + '/payments';
  const requestHeaders = await getRequestHeaders();
  return await axios.get(requestUrl, requestHeaders);
}

export async function getCashflowData() {
  const requestUrl = API_ROOT + getStoreId() + '/cashflow';
  const requestHeaders = await getRequestHeaders();
  return await axios.post(requestUrl, undefined, requestHeaders);
}

export async function getCashflowWeeklyData() {
  const requestUrl = API_ROOT + getStoreId() + '/cashflow/weekly';
  const requestHeaders = await getRequestHeaders();
  return await axios.get(requestUrl, requestHeaders);
}

// <----------------------------------------------------Events----------------------------------------------------------------->
export async function getEventsTopSeller() {
  const requestUrl = API_ROOT + 'events-data';
  const requestBody = JSON.stringify({
    dataType: 'TopSeller',
  });
  return await axios.post(requestUrl, requestBody, { REQUEST_CONFIG });
}

export async function getEventsLatestProduct() {
  const requestUrl = API_ROOT + 'events-data';
  const requestBody = JSON.stringify({
    dataType: 'LatestProduct',
  });
  return await axios.post(requestUrl, requestBody, { REQUEST_CONFIG });
}

// <----------------------------------------------------Performance----------------------------------------------------------------->
export async function getPerformanceTopProducts() {
  const requestUrl = API_ROOT + getStoreId() + '/performance-data';
  const requestBody = JSON.stringify({
    dataType: 'TopSellingProducts',
  });
  const requestHeaders = await getRequestHeaders();
  return await axios.post(requestUrl, requestBody, requestHeaders);
}

export async function getPerformanceSalesByVolume() {
  const requestUrl = API_ROOT + getStoreId() + '/performance-data';
  const requestBody = JSON.stringify({
    dataType: 'SalesByVolume',
  });
  const requestHeaders = await getRequestHeaders();
  return await axios.post(requestUrl, requestBody, requestHeaders);
}

export async function getPerformanceSalesByRegion() {
  const requestUrl = API_ROOT + getStoreId() + '/performance-data';
  const requestBody = JSON.stringify({
    dataType: 'SalesByRegion',
  });
  const requestHeaders = await getRequestHeaders();
  return await axios.post(requestUrl, requestBody, requestHeaders);
}

export async function getPerformanceTopCustomers() {
  const requestUrl = API_ROOT + getStoreId() + '/performance-data';
  const requestBody = JSON.stringify({
    dataType: 'TopCustomers',
  });
  const requestHeaders = await getRequestHeaders();
  return await axios.post(requestUrl, requestBody, requestHeaders);
}

export async function getPerformanceGenderBreakdown() {
  const requestUrl = API_ROOT + getStoreId() + '/performance-data';
  const requestBody = JSON.stringify({
    dataType: 'GenderBreakdown',
  });
  const requestHeaders = await getRequestHeaders();
  return await axios.post(requestUrl, requestBody, requestHeaders);
}

export async function getPerformanceSourceBreakdown() {
  const requestUrl = API_ROOT + getStoreId() + '/performance-data';
  const requestBody = JSON.stringify({
    dataType: 'SourceBreakdown',
  });
  const requestHeaders = await getRequestHeaders();
  return await axios.post(requestUrl, requestBody, requestHeaders);
}

export async function getPerformanceSalesByCategory() {
  const requestUrl = API_ROOT + getStoreId() + '/performance-data';
  const requestBody = JSON.stringify({
    dataType: 'SalesByCategory',
  });
  const requestHeaders = await getRequestHeaders();
  return await axios.post(requestUrl, requestBody, requestHeaders);
}

// <----------------------------------------------------Refer Friend----------------------------------------------------------------->
/**
 * Sends a friend referral
 * @param {Object} messageObj object containing name and receiver email and sender's name
 */
export async function inviteFriend(messageObj) {
  const requestUrl = `${API_ROOT}refer-friend`;
  const requestBody = JSON.stringify(messageObj);
  return await axios.post(requestUrl, requestBody);
}

// <----------------------------------------------------Warehouses----------------------------------------------------------------->
/**
 * Create a new warehouse
 */
export async function createWarehouse(name, process, isPrimaryWarehouse) {
  const requestUrl = API_ROOT + getStoreId() + '/warehouses';
  const requestBody = JSON.stringify({
    name: name,
    process: process,
    isPrimaryWarehouse: isPrimaryWarehouse,
  });
  const requestHeaders = await getRequestHeaders();
  return await axios.post(requestUrl, requestBody, requestHeaders);
}

/**
 * Get warehouses
 */
export async function getWarehouses() {
  const requestUrl = API_ROOT + getStoreId() + '/warehouses';
  const requestHeaders = await getRequestHeaders();
  return await axios.get(requestUrl, requestHeaders);
}

/**
 * Get warehouse by warehouseId
 */
export async function getWarehouse(warehouseId) {
  const requestUrl = API_ROOT + getStoreId() + '/warehouses/' + warehouseId;
  const requestHeaders = await getRequestHeaders();
  return await axios.get(requestUrl, requestHeaders);
}

/**
 * Edit warehouse
 */
export async function updateWarehouse(warehouseId, name, process, isPrimaryWarehouse) {
  const requestUrl = API_ROOT + getStoreId() + '/warehouses/' + warehouseId;

  var dict = {};
  if (name !== undefined) dict['name'] = name;
  if (process !== undefined) dict['process'] = process;
  if (isPrimaryWarehouse !== undefined) dict['isPrimaryWarehouse'] = isPrimaryWarehouse;
  const requestBody = JSON.stringify(dict);
  const requestHeaders = await getRequestHeaders();

  return await axios.patch(requestUrl, requestBody, requestHeaders);
}

export async function deleteWarehouse(warehouseId) {
  const requestUrl = API_ROOT + getStoreId() + '/warehouses/' + warehouseId;
  const requestHeaders = await getRequestHeaders();
  return await axios.delete(requestUrl, requestHeaders);
}

// <----------------------------------------------------Inventory----------------------------------------------------------------->
/**
 * Get all inventory
 */
export async function getInventory() {
  const requestUrl = API_ROOT + getStoreId() + '/inventory';
  const requestHeaders = await getRequestHeaders();
  return await axios.get(requestUrl, requestHeaders);
}

/**
 * Get inventory for a warehouse
 */
export async function getWarehouseInventory(warehouseId) {
  const requestUrl = API_ROOT + getStoreId() + '/inventory/' + warehouseId;
  const requestHeaders = await getRequestHeaders();
  return await axios.get(requestUrl, requestHeaders);
}

/*/
 * Get all product activity
 */
export async function getInventoryActivity() {
  const requestUrl = API_ROOT + getStoreId() + '/inventory/activity';
  const requestHeaders = await getRequestHeaders();
  return await axios.get(requestUrl, requestHeaders);
}

/*/
 * Get activity for a product
 */
export async function getProductActivity(productId) {
  const requestUrl = API_ROOT + getStoreId() + '/products/' + productId + '/activity';
  const requestHeaders = await getRequestHeaders();
  return await axios.get(requestUrl, requestHeaders);
}

/**
 * Add inventory to a warehouse
 */
export async function addWarehouseInventory(warehouseId, inventory) {
  const requestUrl = API_ROOT + getStoreId() + '/inventory/' + warehouseId;
  const requestBody = JSON.stringify(inventory);
  const requestHeaders = await getRequestHeaders();
  return await axios.put(requestUrl, requestBody, requestHeaders);
}

/**
 * Move inventory
 */
export async function moveInventory(currentWarehouseId, newWarehouseId, productId, productName, quantity) { 
  const requestUrl = API_ROOT + getStoreId() + '/inventory/' + currentWarehouseId + '/' + productId;
  const requestBody = JSON.stringify({
    action: 'move',
    warehouseId: newWarehouseId,
    quantity: quantity,
    productName: productName,
  });
  const requestHeaders = await getRequestHeaders();
  return await axios.patch(requestUrl, requestBody, requestHeaders);
}

/**
 * Remove inventory
 */
export async function removeInventory(currentWarehouseId, productId, productName, quantity, untrackItem) {
  const requestUrl = API_ROOT + getStoreId() + '/inventory/' + currentWarehouseId + '/' + productId;
  const requestBody = JSON.stringify({
    action: 'remove',
    warehouseId: '',
    quantity: quantity,
    untrackItem: untrackItem,
    productName: productName,
  });
  const requestHeaders = await getRequestHeaders();
  return await axios.patch(requestUrl, requestBody, requestHeaders);
}

/**
 * Transform inventory
 */
export async function transformInventory(
  currentWarehouseId,
  newWarehouseId,
  process,
  productId,
  productName, 
  newProductId,
  quantity,
) {
  const requestUrl = API_ROOT + getStoreId() + '/inventory/' + currentWarehouseId + '/' + productId;
  const requestBody = JSON.stringify({
    action: 'transform',
    warehouseId: newWarehouseId,
    process: process,
    productId: newProductId,
    quantity: quantity,
    productName: productName,
  });
  const requestHeaders = await getRequestHeaders();
  return await axios.patch(requestUrl, requestBody, requestHeaders);
}

// <----------------------------------------------------Roles----------------------------------------------------------------->
export async function getRolesFromAWS(abortController) {
  const reqConf = await getReqConfWithAbort(abortController);
  const fetchRolesPromise = new Promise((resolve, reject) => {
    const requestUrl = API_ROOT + getStoreId() + '/roles';
    axios
      .get(requestUrl, reqConf)
      .then(async (response) => {
        // console.log(response)
        resolve(response.data);
      })
      .catch((e) => {
        console.log(e);
        resolve([]);
      });
  });

  const fetchedRoles = await Promise.all([fetchRolesPromise]);
  return fetchedRoles[0];
}

export async function deleteRoleFromAWS(abortController, id) {
  const reqConf = await getReqConfWithAbort(abortController);
  const requestUrl = API_ROOT + getStoreId() + '/roles/' + id;
  return await axios.delete(requestUrl, reqConf);
}

export async function getPostRoleToAWS(role) {
  const requestUrl = API_ROOT + getStoreId() + '/roles';
  const requestBody = JSON.stringify({
    storeID: getStoreId(),
    role: role,
  });
  const requestHeaders = await getRequestHeaders();
  return await axios.post(requestUrl, requestBody, requestHeaders);
}

export async function updateRoleToAWS(role) {
  const requestUrl = API_ROOT + getStoreId() + '/roles';
  const requestBody = JSON.stringify({
    storeID: getStoreId(),
    role: role,
  });
  const requestHeaders = await getRequestHeaders();
  return await axios.patch(requestUrl, requestBody, requestHeaders);
}

// <----------------------------------------------------Users----------------------------------------------------------------->
export async function getUsersFromAWS(abortController) {
  const reqConf = await getReqConfWithAbort(abortController);
  const fetchUsersPromise = new Promise((resolve, reject) => {
    const requestUrl = API_ROOT + getStoreId() + '/users';
    axios
      .get(requestUrl, reqConf)
      .then(async (response) => {
        // console.log(response)
        resolve(response.data);
      })
      .catch((e) => {
        console.log(e);
        resolve([]);
      });
  });

  const fetchedUsers = await Promise.all([fetchUsersPromise]);
  return fetchedUsers[0];
}

export async function getUserFromAWS(inviteId) {
  const requestUrl = API_ROOT + 'invited-user-data/' + inviteId;
  return await axios.get(requestUrl, REQUEST_CONFIG);
}

export async function deleteUserFromAWS(abortController, id) {
  const requestUrl = API_ROOT + getStoreId() + '/users/' + id;
  const reqConf = await getReqConfWithAbort(abortController);
  return await axios.delete(requestUrl, reqConf);
}

export async function getPostUserToAWS(user) {
  const requestUrl = API_ROOT + getStoreId() + '/users';
  const requestBody = JSON.stringify({
    storeID: getStoreId(),
    user: user,
  });
  const requestHeaders = await getRequestHeaders();
  return await axios.post(requestUrl, requestBody, requestHeaders);
}

export async function updateUserToAWS(user) {
  const requestUrl = API_ROOT + getStoreId() + '/users';
  const requestBody = JSON.stringify({
    storeID: getStoreId(),
    user: user,
  });
  const requestHeaders = await getRequestHeaders();
  return await axios.patch(requestUrl, requestBody, requestHeaders);
}

export async function getSubscription() {
  const requestUrl = API_ROOT + getStoreId() + '/subscription';
  const requestHeaders = await getRequestHeaders();
  return await axios.get(requestUrl, requestHeaders);
}

export const createSubscription = async (subscription) => {
  const requestUrl = API_ROOT + getStoreId() + '/subscription';
  const requestBody = JSON.stringify(subscription);
  const requestHeaders = await getRequestHeaders();
  return await axios.post(requestUrl, requestBody, requestHeaders);
};

export const getPaymentHistory = async (year) => {
  const requestUrl = API_ROOT + getStoreId() + `/subscription/batch?year=${year}`;
  const requestHeaders = await getRequestHeaders();
  return await axios.get(requestUrl, requestHeaders);
};

export async function stripeCheckout(subscription) {
  const requestUrl = API_ROOT + 'stripe-checkout';
  const requestBody = JSON.stringify({
    storeID: getStoreId(),
    subscription: subscription,
  });
  return await axios.post(requestUrl, requestBody, REQUEST_CONFIG);
}

export async function createTaxArchive(invoices) {
  const requestUrl = API_ROOT + getStoreId() + '/tax-archive';
  const requestBody = JSON.stringify({
    'invoices': invoices
  });
  const requestHeaders = await getRequestHeaders();
  return await axios.post(requestUrl, requestBody, requestHeaders);
}

/**
 * @param {Object} param0
 * @param {string} param0.subscriptionId
 * @param {Array<string>} param0.feedback
 * @param {string} param0.otherReason
 */
export async function updateCancellationFeedback({ feedback, otherReason, subscriptionId }) {
  const requestUrl = API_ROOT + getStoreId() + '/subscription/cancellation-feedback?subscriptionId=' + subscriptionId;
  const requestHeaders = await getRequestHeaders();
  return await axios.put(requestUrl, { feedback, otherReason }, requestHeaders);
}
export async function getTaxArchives(){
  const requestUrl = API_ROOT + getStoreId() + '/tax-archive';
  const requestHeaders = await getRequestHeaders();
  return await axios.get(requestUrl, requestHeaders);
}

export async function getTaxArchive(taxArchiveId){
  const requestUrl = API_ROOT + getStoreId() + '/tax-archive/' + taxArchiveId;
  const requestHeaders = await getRequestHeaders();
  return await axios.get(requestUrl, requestHeaders);
}

export async function getTaxArchiveTracker(){
  const requestUrl = API_ROOT + getStoreId() + '/tax-archive/invoices';
  const requestHeaders = await getRequestHeaders();
  return await axios.get(requestUrl, requestHeaders);
}

export async function getTaxArchiveInvoices(){
  const requestUrl = API_ROOT + getStoreId() + '/tax-archive/invoices';
  const requestHeaders = await getRequestHeaders();
  return await axios.get(requestUrl, requestHeaders);
}

export const uploadFileToS3 = async ({ file }) => {
  const key = getStoreId();
  const requestUrl = API_ROOT + '/images/' + key;
  await axios.put(requestUrl, file, {
    headers: {
      'x-api-key': process.env.REACT_APP_API_KEY,
      'Content-Type': 'multipart/form-data',
    },
  });
  return { key, name: file.name, type: file.type, size: file.size };
};

export const uploadTaxArchiveFiles = async ({ files, taxArchiveId }) => {
  const requestUrl = API_ROOT + getStoreId()+ '/tax-archive/'  + taxArchiveId + '/files';
  const requestHeaders = await getRequestHeaders();
  return await axios.put(requestUrl, { files }, requestHeaders);
}

export const updateExcludedInvoices = async (invoices) => {
  const requestUrl = API_ROOT + getStoreId()+ '/tax-archive' + '/exclude-invoices';
  const requestHeaders = await getRequestHeaders();
  return await axios.put(requestUrl, { invoices }, requestHeaders);
}

//popbill (issue tax invoice)

export const checkPopbillMember = async (type, value) => {
  const requestURL = API_ROOT + getStoreId() + '/tax-archive/popbill/check-member';
  const requestBody = {
    type: type,
    value: value
  };
  const requestHeaders = await getRequestHeaders();
  return await axios.post(requestURL, requestBody, requestHeaders);
}

export const joinPopbill = async (user) => {
  const requestURL = API_ROOT + getStoreId() + '/tax-archive/popbill/user';
  const requestBody = user;
  const requestHeaders = await getRequestHeaders();
  return await axios.post(requestURL, requestBody, requestHeaders);
}

export const quitPopbill = async (user) => {
  const requestURL = API_ROOT + getStoreId() + '/tax-archive/popbill/user';
  const requestBody = user;
  const requestHeaders = await getRequestHeaders();
  return await axios.patch(requestURL, requestBody, requestHeaders);
}

export const getCertUrl = async (corpNum, popbillId) => {
  const requestURL = API_ROOT + getStoreId() + '/tax-archive/popbill/' + corpNum;
  const requestBody = {
    id: popbillId
  };
  const requestHeaders = await getRequestHeaders();
  return await axios.post(requestURL, requestBody, requestHeaders);
}

export const getCertInfo = async (corpNum) => {
  const requestURL = API_ROOT + getStoreId() + '/tax-archive/popbill/' + corpNum;
  const requestHeaders = await getRequestHeaders();
  return await axios.get(requestURL, requestHeaders);
}

export const submitTaxInvoiceToHometax = async (corpNum, taxInvoices) => {
  const requestURL = API_ROOT + getStoreId() + '/tax-archive/popbill/submit';
  const requestBody = {
    corpNum: corpNum,
    invoices: taxInvoices,
  };
  const requestHeaders = await getRequestHeaders();
  return await axios.post(requestURL, requestBody, requestHeaders);
}

export const getSubmittedTaxInvoice = async (corpNum, popbillId, search) => {
  const requestURL = API_ROOT + getStoreId() + '/tax-archive/popbill';
  const requestHeaders = await getRequestHeaders();
  const requestBody = JSON.stringify({
    corpNum: corpNum,
    popbillId: popbillId,
    search: search,
  });
  return await axios.post(requestURL, requestBody, requestHeaders);
}

export const getSubmittedTaxInvoiceDetail = async (corpNum, keyType, key) => {
  const requestURL = API_ROOT + getStoreId() + '/tax-archive/popbill/submit/tax-invoice';
  const requestHeaders = await getRequestHeaders();
  const requestBody = JSON.stringify({
    corpNum: corpNum,
    mgtKeyType: keyType,
    mgtKey: key,
  });
  return await axios.post(requestURL, requestBody, requestHeaders);
}
export const assignTaxInvoiceId = async (id, corpNum, keyType, key) => {
  const requestURL = API_ROOT + getStoreId() + '/tax-archive/popbill/submit/tax-invoice/' + id;
  const requestHeaders = await getRequestHeaders();
  const requestBody = JSON.stringify({
    corpNum: corpNum,
    mgtKeyType: keyType,
    mgtKey: key,
  });
  return await axios.post(requestURL, requestBody, requestHeaders);
}


// Update Stock History Data
export const updateStockHistoryData = async (warehouseId, productId, data) => {
  const requestURL = API_ROOT + getStoreId() + '/inventory/' + warehouseId + '/' + productId + '/stockHistory';
  const requestBody = {
    stockHistoryId: data?.stockHistoryId,
    timestamp: data?.timestamp,
    unitPrice: Number(data?.unitPrice),
    quantity: Number(data?.quantity)
  };
  const requestHeaders = await getRequestHeaders();
  return await axios.patch(requestURL, requestBody, requestHeaders);
}

// Get Product
export const getProductAws = async (productId) => {
  const requestURL = API_ROOT + getStoreId() + '/products/' + productId + '/single';
  const requestHeaders = await getRequestHeaders();
  return await axios.get(requestURL, requestHeaders);
}

//Post Import Inventory
export async function postImportInventory(requestBody) {
  const requestUrl = API_ROOT + getStoreId() + '/bulk-inventory';
  const requestHeaders = await getRequestHeaders();
  return await axios.put(requestUrl, requestBody, requestHeaders);
}