import { useState, useEffect, useRef } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { styled, useTheme } from '@mui/material/styles';
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';
import { useTranslation } from "react-i18next";
// eslint-disable-next-line no-unused-vars
import i18n from "../../locales/i18n";

// ----------------------------------------------------------------------
const APP_BAR_MOBILE = 64;

const APP_BAR_DESKTOP = 92;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden'
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}));

// ----------------------------------------------------------------------
export default function DashboardLayout() {
  // eslint-disable-next-line no-unused-vars
  const { i18n, t: translate } = useTranslation();
  const location = useLocation();
  const theme = useTheme();

  const [open, setOpen] = useState(false);
  var [title, setTitle] = useState("");
  var [subTitle, setSubTitle] = useState("");

  // This method checks the pathname of the page on load, and passes the appropriate headers to the nav. 
  useEffect(() => {

    const pathname = location.pathname;

    switch (true) {
      // -----------------------------------
      // Command Central
      case /^\/dashboard\/commandcentral$/.test(pathname):
        setTitle(i18n.t('dashboard.commandCentral.title'));//'Command Central');
        setSubTitle(i18n.t('dashboard.commandCentral.subtitle'));//'Get a bird's eye view of your business');
        break;
      // Order Management
      case /^\/dashboard\/offlineorders$/.test(pathname):
        setTitle(i18n.t('dashboard.offlineOrders.title'));//'Offline Orderflow Processing');
        setSubTitle(i18n.t('dashboard.offlineOrders.subtitle'));//'Digitize orderflow coming in from unconventional intake');
        break;
      case /^\/dashboard\/offlineorders\/new$/.test(pathname):
        setTitle(i18n.t('dashboard.offlineOrders.title'));//'Offline Orderflow Processing');
        setSubTitle(i18n.t('dashboard.offlineOrders.subtitle'));//'Digitize orderflow coming in from unconventional intake');
        break;
      case /^\/dashboard\/offlineorders\/edit$/.test(pathname):
        setTitle(i18n.t('dashboard.offlineOrders.title'));//'Offline Orderflow Processing');
        setSubTitle(i18n.t('dashboard.offlineOrders.subtitle'));//'Digitize orderflow coming in from unconventional intake');
        break;
      case /^\/dashboard\/onlineorders$/.test(pathname):
        setTitle(i18n.t('dashboard.onlineOrders.title'));//'Online Orderflow Processing');
        setSubTitle(i18n.t('dashboard.onlineOrders.subtitle'));//'Aggregate orderflow from all the major operations platforms');
        break;
      case /^\/dashboard\/orderstatus$/.test(pathname):
        setTitle(i18n.t('dashboard.orderStatus.title'));//'Manage Order Fufillment'); 
        setSubTitle(i18n.t('dashboard.orderStatus.subtitle'));//'View the status of pending orders and review completed orders');
        break;
      // -----------------------------------
      // Marketing Tools
      case /^\/dashboard\/tools\/sellgpt$/.test(pathname):
        setTitle(i18n.t('dashboard.marketingTools.sellGpt.title'));
        setSubTitle(i18n.t('dashboard.marketingTools.sellGpt.subtitle'));
        break;
      case /^\/dashboard\/tools\/buzzwords$/.test(pathname):
        setTitle(i18n.t('dashboard.marketingTools.buzzwords.title'));//'Search Engine Optimization'); 
        setSubTitle(i18n.t('dashboard.marketingTools.buzzwords.subtitle'));//'Identify keywords to enhance your listings for higher pageviews');
        break;
      case /^\/dashboard\/tools\/hotproducts$/.test(pathname):
        setTitle(i18n.t('dashboard.marketingTools.hotProducts.title'));//'Trending Products'); 
        setSubTitle(i18n.t('dashboard.marketingTools.hotProducts.subtitle'));//'Gain insights from actual sales data to know what\'s hot and what\'s not');
        break;
      case /^\/dashboard\/tools\/pricematch$/.test(pathname):
        setTitle(i18n.t('dashboard.marketingTools.priceMatch.title'));//'Price Action Analysis'); 
        setSubTitle(i18n.t('dashboard.marketingTools.priceMatch.subtitle'));//'Keep your pricing competitive with the market. Optimize your margins for more profit');
        break;
      // -----------------------------------
      // Financials
      case /^\/dashboard\/financials\/cashflow$/.test(pathname):
        setTitle(i18n.t('dashboard.cashflow.title'));//'Cashflow'); // TODO: change text
        setSubTitle(i18n.t('dashboard.cashflow.subtitle'));//'Keep track of your financial cashflow'); // TODO: change text
        break;
      // Invoice
      case /^\/dashboard\/invoice$/.test(pathname):
        setTitle(i18n.t('dashboard.invoicing.title'));//'Invoice Tracking'); 
        setSubTitle(i18n.t('dashboard.invoicing.subtitle'));//'View past and current transactions or create new ones');
        break;
      case /^\/dashboard\/invoice\/new$/.test(pathname):
        setTitle(i18n.t('dashboard.invoicing.new.title'));//'Create a New Invoice'); 
        setSubTitle(i18n.t('dashboard.invoicing.new.subtitle'));//'Build an invoice to bill clients for goods and services');
        break;
      case /^\/dashboard\/invoice\/[a-zA-Z0-9-]+\/invoiceView$/.test(pathname):
        setTitle(i18n.t('dashboard.invoicing.view.title'));//'Invoice View');
        setSubTitle(i18n.t('dashboard.invoicing.view.subtitle'));//'View invoice to bill clients for goods and services'); // TODO: change text
        break;
      case /^\/dashboard\/invoice\/[a-zA-Z0-9-]+\/onlineView$/.test(pathname):
        setTitle(i18n.t('dashboard.invoicing.view.title'));//'Invoice View');
        setSubTitle(i18n.t('dashboard.invoicing.view.subtitle'));//'View invoice to bill clients for goods and services'); // TODO: change text
        break;
      case /^\/dashboard\/invoice\/[a-zA-Z0-9-]+\/offlineView$/.test(pathname):
        setTitle(i18n.t('dashboard.invoicing.view.title'));//'Invoice View');
        setSubTitle(i18n.t('dashboard.invoicing.view.subtitle'));//'View invoice to bill clients for goods and services'); // TODO: change text
        break;
      case /^\/dashboard\/invoice\/[a-zA-Z0-9-]+\/procurementView$/.test(pathname):
        setTitle(i18n.t('dashboard.invoicing.view.title'));//'Procurement View');
        setSubTitle(i18n.t('dashboard.invoicing.view.subtitle'));//'View invoice to bill clients for goods and services'); // TODO: change text
        break;
      case /^\/dashboard\/invoice\/[a-zA-Z0-9-]+\/offlineBatchView$/.test(pathname):
        setTitle(i18n.t('dashboard.invoicing.view.title'));//'Invoice View');
        setSubTitle(i18n.t('dashboard.invoicing.view.subtitle'));//'View invoice to bill clients for goods and services'); // TODO: change text
        break;
      case /^\/dashboard\/invoice\/[a-zA-Z0-9-]+\/invoiceEdit$/.test(pathname):
        setTitle(i18n.t('dashboard.invoicing.edit.title.default')); // 'Invoice Edit'
        setSubTitle(i18n.t('dashboard.invoicing.edit.subtitle')); // 'Edit invoice to bill clients for goods and services'
        break;
      case /^\/dashboard\/invoice\/[a-zA-Z0-9-]+\/onlineEdit$/.test(pathname):
        setTitle(i18n.t('dashboard.invoicing.edit.title.onlineOrder')); // 'Invoice Edit (Online Order)'
        setSubTitle(i18n.t('dashboard.invoicing.edit.subtitle')); // 'Edit invoice to bill clients for goods and services'
        break;
      case /^\/dashboard\/invoice\/[a-zA-Z0-9-]+\/offlineEdit$/.test(pathname):
        setTitle(i18n.t('dashboard.invoicing.edit.title.offlineOrder')); // 'Invoice Edit (Offline Order)'
        setSubTitle(i18n.t('dashboard.invoicing.edit.subtitle')); // 'Edit invoice to bill clients for goods and services'
        break;
      // Payment
      case /^\/dashboard\/payment$/.test(pathname):
        setTitle(i18n.t('dashboard.payment.title'));
        setSubTitle(i18n.t('dashboard.payment.subtitle'));
        break;
      case /^\/dashboard\/payment\/[a-zA-Z0-9-]+\/paymentView$/.test(pathname):
        setTitle(i18n.t('dashboard.payment.paymentView.title'));
        setSubTitle(i18n.t('dashboard.payment.paymentView.subtitle'));
        break;
      // -----------------------------------
      // Commerce
      case /^\/dashboard\/operations\/events$/.test(pathname):
        setTitle(i18n.t('dashboard.operations.events.title'));//'Events and Promotions');
        setSubTitle(i18n.t('dashboard.operations.events.subtitle'));//'Get the scoop on the latest and greatest');
        break;
      case /^\/dashboard\/operations\/performance$/.test(pathname):
        setTitle(i18n.t('dashboard.operations.performance.title'));//'Performance Analytics'); 
        setSubTitle(i18n.t('dashboard.operations.performance.subtitle'));//'Slice and dice your data and compare metrics to track your progress');
        break;
      case /^\/dashboard\/operations\/store$/.test(pathname):
        setTitle(i18n.t('dashboard.operations.store.title'));//'Shop Wholesale Goods');
        setSubTitle(i18n.t('dashboard.operations.store.subtitle'));//'Browse our inventory of hot products');
        break;
      case /^\/dashboard\/operations\/catalogue$/.test(pathname):
        setTitle(i18n.t('dashboard.operations.catalogue.title'));//'Manage Store Catalogue'); 
        setSubTitle(i18n.t('dashboard.operations.catalogue.subtitle'));//'Create, update or delete products in your inventory');
        break;
      case /^\/dashboard\/operations\/catalogue\/edit$/.test(pathname):
        setTitle(i18n.t('dashboard.operations.catalogue.title'));//'Manage Store Catalogue'); 
        setSubTitle(i18n.t('dashboard.operations.catalogue.subtitle'));//'Create, update or delete products in your inventory');
        break;
      case /^\/dashboard\/operations\/catalogue\/config\/[a-zA-Z0-9%-]+$/.test(pathname):
        setTitle(i18n.t('dashboard.operations.catalogue.title'));//'Update Store Catalogue'); 
        setSubTitle(i18n.t('dashboard.operations.catalogue.subtitle'));
        break;
      case /^\/dashboard\/operations\/catalogue\/new$/.test(pathname):
        setTitle(i18n.t('dashboard.operations.catalogue.new.title'));//'Update Store Catalogue'); 
        setSubTitle(i18n.t('dashboard.operations.catalogue.new.subtitle'));
        break;
      case /^\/dashboard\/operations\/catalogue\/new\/[a-zA-Z0-9%-]+$/.test(pathname):
        setTitle(i18n.t('dashboard.operations.catalogue.new.title'));//'Update Store Catalogue'); 
        setSubTitle(i18n.t('dashboard.operations.catalogue.new.subtitle'));
        break;
      case /^\/dashboard\/operations\/procurement$/.test(pathname):
        setTitle(i18n.t('dashboard.operations.procurement.title'));//'Manage Procurement'); 
        setSubTitle(i18n.t('dashboard.operations.procurement.subtitle'));//'Oversee procurement and manufacturing of goods');
        break;
      case /^\/dashboard\/operations\/procurement\/new$/.test(pathname):
        setTitle(i18n.t('dashboard.operations.procurement.title'));//'Manage Procurement'); 
        setSubTitle(i18n.t('dashboard.operations.procurement.subtitle'));//'Oversee procurement and manufacturing of goods');
        break;
      case /^\/dashboard\/operations\/procurement\/edit\/[a-zA-Z0-9%-]+$/.test(pathname):
        setTitle(i18n.t('dashboard.operations.procurement.title'));//'Manage Procurement'); 
        setSubTitle(i18n.t('dashboard.operations.procurement.subtitle'));//'Oversee procurement and manufacturing of goods');
        break;
      case /^\/dashboard\/operations\/inventory$/.test(pathname):
        setTitle(i18n.t('dashboard.operations.inventory.title'));//'Inventory Management');
        setSubTitle(i18n.t('dashboard.operations.inventory.subtitle'));//'Track and manage your inventory between warehouses');
        break;
      case /^\/dashboard\/operations\/inventory\/new$/.test(pathname):
        setTitle(i18n.t('dashboard.operations.inventory.new.title'));//'Create Warehouse'
        setSubTitle(i18n.t('dashboard.operations.inventory.subtitle'));
        break;
      case /^\/dashboard\/operations\/inventory\/edit\/[a-zA-Z0-9%-]+$/.test(pathname):
        setTitle(i18n.t('dashboard.operations.inventory.edit.title'));//'Edit Warehouse'
        setSubTitle(i18n.t('dashboard.operations.inventory.subtitle'));
        break;
      case /^\/dashboard\/operations\/inventory\/[a-zA-Z0-9%-]+$/.test(pathname):
        setTitle(i18n.t('dashboard.operations.inventory.detail.title'));//'Warehouse Details'
        setSubTitle(i18n.t('dashboard.operations.inventory.subtitle'));
        break;
      case /^\/dashboard\/operations\/customerdirectory$/.test(pathname):
        setTitle(i18n.t('dashboard.operations.customerDirectory.title'));//'Customer Directory');
        setSubTitle(i18n.t('dashboard.operations.customerDirectory.subtitle'));//'Manage, flag, and ban customers from your store');
        break;
      case /^\/dashboard\/operations\/customerdirectory\/new$/.test(pathname):
        setTitle(i18n.t('dashboard.operations.customerDirectory.title'));//'Customer Directory');
        setSubTitle(i18n.t('dashboard.operations.customerDirectory.subtitle'));
        break;
      case /^\/dashboard\/operations\/[a-zA-Z0-9-]+\/customerEdit$/.test(pathname):
        setTitle(i18n.t('dashboard.operations.customerDirectory.title'));//'Customer Directory');
        setSubTitle(i18n.t('dashboard.operations.customerDirectory.subtitle'));
        break;
      case /^\/dashboard\/operations\/[a-zA-Z0-9-]+\/productView$/.test(pathname):
        setTitle(i18n.t('dashboard.operations.product.title'));
        setSubTitle(i18n.t('dashboard.operations.product.view.subtitle'));
        break;
      case /^\/dashboard\/operations\/[a-zA-Z0-9-]+\/productEdit$/.test(pathname):
        setTitle(i18n.t('dashboard.operations.product.title'));
        setSubTitle(i18n.t('dashboard.operations.product.edit.subtitle'));
        break;
      // -----------------------------------
      // Checkout
      case /^\/dashboard\/operations\/checkout$/.test(pathname):
        setTitle('Shopping Cart');
        setSubTitle('Order processing');
        break;
      // -----------------------------------
      // Support
      case /^\/dashboard\/inbox$/.test(pathname):
        setTitle(i18n.t('dashboard.support.contactUs.title'));//'Send us an Inquiry'); 
        setSubTitle(i18n.t('dashboard.support.contactUs.subtitle'));//'Let us know about any questions or concerns');
        break;
      case /^\/dashboard\/userguide$/.test(pathname):
        setTitle(i18n.t('dashboard.support.userGuide.title'));//'Send us an Inquiry'); 
        setSubTitle(i18n.t('dashboard.support.userGuide.subtitle'));//'Let us know about any questions or concerns');
        break;
      // -----------------------------------
      // Settings
      case /^\/dashboard\/settings$/.test(pathname):
        setTitle(i18n.t('dashboard.accountSettings.title'));//'Account Settings');
        setSubTitle(i18n.t('dashboard.accountSettings.subtitle'));//'View or update your Sellpoint account information');
        break;
      // Admin Pannel
      // User Management
      case /^\/dashboard\/admin\/usermanagement$/.test(pathname):
        setTitle(i18n.t('dashboard.admin.userManagement.title'));// User Management
        setSubTitle(i18n.t('dashboard.admin.userManagement.subtitle'));// You can grant or revoke Sellpoint privileges
        break;
      // User Information Management
      case /^\/dashboard\/admin\/usermanagement\/[a-zA-Z0-9-]+\/information$/.test(pathname):
        setTitle(i18n.t('dashboard.admin.userInformationManagement.title'));//'User Information Management');
        setSubTitle(i18n.t('dashboard.admin.userInformationManagement.subtitle'));//'You can manage user information');
        break;
      // Role Management
      case /^\/dashboard\/admin\/rolemanagement$/.test(pathname):
        setTitle(i18n.t('dashboard.admin.roleManagement.title'));//'User Information Management');
        setSubTitle(i18n.t('dashboard.admin.roleManagement.subtitle'));//'You can manage user information');
        break;
      // Role Creation Management
      case /^\/dashboard\/admin\/rolemanagement\/new$/.test(pathname):
        setTitle(i18n.t('dashboard.admin.roleCreationManagement.title'));//'User Information Management');
        setSubTitle(i18n.t('dashboard.admin.roleCreationManagement.subtitle'));//'You can manage user information');
        break;
      // Role Edit Management
      case /^\/dashboard\/admin\/rolemanagement\/[a-zA-Z0-9-]+\/edit$/.test(pathname):
        setTitle(i18n.t('dashboard.admin.roleEditManagement.title'));//'User Information Management');
        setSubTitle(i18n.t('dashboard.admin.roleEditManagement.subtitle'));//'You can manage user information');
        break;
      case /^\/dashboard\/operations\/catalogue\/create\/raw-material$/.test(pathname):
        setTitle(i18n.t('createRawMaterialTemplate.title'));//'User Information Management');
        setSubTitle(i18n.t('createRawMaterialTemplate.subtitle'));//'You can manage user information');
        break;
      case /^\/dashboard\/operations\/catalogue\/create\/raw-material\/[a-zA-Z0-9-]+$/.test(pathname):
        setTitle(i18n.t('createRawMaterialTemplate.title'));//'User Information Management');
        setSubTitle(i18n.t('createRawMaterialTemplate.subtitle'));//'You can manage user information');
        break;
      case /^\/dashboard\/operations\/catalogue\/create\/raw-material\/[a-zA-Z0-9-]+\/duplicate$/.test(pathname):
        setTitle(i18n.t('createRawMaterialTemplate.title'));//'User Information Management');
        setSubTitle(i18n.t('createRawMaterialTemplate.subtitle'));//'You can manage user information');
        break;
      case /^\/dashboard\/subscription\/payment$/.test(pathname):
        setTitle(i18n.t('subscription.title'));//'User Information Management');
        setSubTitle(i18n.t('subscription.subtitle'));//'You can manage user information');
        break;
      // http://localhost:3000/dashboard/subscription/payment/cancel
      case /^\/dashboard\/subscription\/payment\/cancel$/.test(pathname):
        setTitle(i18n.t('subscription.title'));//'User Information Management');
        setSubTitle(i18n.t('subscription.subtitle'));//'You can manage user information');
        break;
      // http://localhost:3000/dashboard/subscription/payment/cancel/confirmation
      case /^\/dashboard\/subscription\/payment\/cancel\/confirmation$/.test(pathname):
        setTitle(i18n.t('subscription.title'));//'User Information Management');
        setSubTitle(i18n.t('subscription.subtitle'));//'You can manage user information');
        break;
      // http://localhost:3000/dashboard/subscription/payment/method?billingCycle=annual&plan=premium
      case /^\/dashboard\/subscription\/payment\/method$/.test(pathname):
        setTitle(i18n.t('subscription.label.selectPaymentMethod'));//'User Information Management');
        setSubTitle(i18n.t('subscription.subtitle'));//'You can manage user information');
        break;

        // /dashboard/taxes
      case /^\/dashboard\/taxes$/.test(pathname):
        setTitle(i18n.t('taxArchive.title'));//'User Information Management');
        setSubTitle(i18n.t('taxArchive.subtitle'));//'You can manage user information');
        break;
        // http://localhost:3000/dashboard/taxes/2023-4
      case /^\/dashboard\/taxes\/[0-9]{4}-[0-9]{1}$/.test(pathname):
        setTitle(i18n.t('taxArchive.title'));//'User Information Management');
        setSubTitle(i18n.t('taxArchive.subtitle'));//'You can manage user information');
        break;
      //http://localhost:3000/dashboard/taxes/2023-4/month/12
      case /^\/dashboard\/taxes\/[0-9]{4}-[0-9]{1}\/month\/[0-9]{1,2}$/.test(pathname):
        setTitle(i18n.t('taxArchive.title'));//'User Information Management');
        setSubTitle(i18n.t('taxArchive.subtitle'));//'You can manage user information');
        break;
        // http://localhost:3000/dashboard/taxes/2023-4/month/12/expense
      case /^\/dashboard\/taxes\/[0-9]{4}-[0-9]{1}\/month\/[0-9]{1,2}\/expense$/.test(pathname):
        setTitle(i18n.t('taxArchive.title'));//'User Information Management');
        setSubTitle(i18n.t('taxArchive.subtitle'));//'You can manage user information');
        break;
      // http://localhost:3000/dashboard/taxes/2023-4/month/12/payment
      case /^\/dashboard\/taxes\/[0-9]{4}-[0-9]{1}\/month\/[0-9]{1,2}\/payment$/.test(pathname):
        setTitle(i18n.t('taxArchive.title'));//'User Information Management');
        setSubTitle(i18n.t('taxArchive.subtitle'));//'You can manage user information');
        break;
        
      //http://localhost:3000/dashboard/taxes/invoice/1dd42ee6-ed2d-472e-ac11-0b8cd79f66c3?type=Generic
      case /^\/dashboard\/taxes\/invoice\/[a-zA-Z0-9-]+/.test(pathname):
        setTitle(i18n.t('taxArchive.title'));//'User Information Management');
        setSubTitle(i18n.t('taxArchive.subtitle'));//'You can manage user information');
        break;
        
      case /^\/dashboard\/operations\/catalogue\/create\/manufactured-products$/.test(pathname):
        setTitle(i18n.t('createManufacturedProductTemplate.title'));//'User Information Management');
        setSubTitle(i18n.t('createManufacturedProductTemplate.subtitle'));//'You can manage user information');
        break;
      
      case /^\/dashboard\/operations\/catalogue\/create\/manufactured-products\/[a-zA-Z0-9-]+\/productEdit$/.test(pathname):
        setTitle(i18n.t('createManufacturedProductTemplate.title'));//'User Information Management');
        setSubTitle(i18n.t('createManufacturedProductTemplate.subtitle'));//'You can manage user information');
        break;
      // -----------------------------------
      default:
        setTitle(i18n.t('dashboard.default.title'));//'Welcome to Sellpoint');
        setSubTitle(i18n.t('dashboard.default.subtitle'));//Focus on what really matters, and let us take care of the rest');
        break;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  
  const [navbarHeight, setNavbarHeight] = useState(0);
  const navbarHeightRef = useRef(null)
  useEffect(() => {
    setNavbarHeight(navbarHeightRef.current.childNodes[0].clientHeight);
  }, [title, subTitle, theme])

  return (
    <RootStyle>
      <div ref={navbarHeightRef}>
        <DashboardNavbar
          headerDescription={{ title, subTitle }} onOpenSidebar={() => setOpen(true)}
        />
      </div>
      <DashboardSidebar
        isOpenSidebar={open} onCloseSidebar={() => setOpen(false)}
      />
      <MainStyle
        sx={{
          [theme.breakpoints.down('md')]: {
            marginTop: [(navbarHeight - 90) + "px"],
          },
          [theme.breakpoints.down('sm')]: {
            marginTop: [(navbarHeight - 70) + "px"],
          },
          [theme.breakpoints.up('md')]: {
            marginTop: 0,
          },
          transition: theme.transitions.create('margin', {
            duration: theme.transitions.duration.complex
          }),
        }}
      >
        <Outlet />
      </MainStyle>
    </RootStyle>
  );
}