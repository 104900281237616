import { Suspense } from "react";
import { retryLazy as lazy } from "src/utils/lazyUtil";
import { Navigate, useRoutes, useLocation } from "react-router-dom";
import MainLayout from "../layouts/main";
import DashboardLayout from "../layouts/dashboard";
import LogoOnlyLayout from "../layouts/LogoOnlyLayout";
import LoadingScreen from "../components/LoadingScreen";
import Checkout from "src/components/catalogue-feature/Checkout";
import GuestGuard from "../components/guards/GuestGuard";
import AuthGuardRedirect from "src/components/guards/AuthGuardRedirect";
import RoleBasedGuard from "src/components/guards/RoleBasedGuard";
import SubscriptionBasedGuard from "src/components/common/SubscriptionBasedGuard";
import { CognitoContext } from "src/contexts/CognitoContext";
import { useCognitoContextProvider } from "src/hooks/useCognitoContextProvider";

// ----------------------------------------------------------------------
const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  return (
    <Suspense
      fallback={<LoadingScreen isDashboard={pathname.includes("/dashboard")} />}
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  const cognitoContext = useCognitoContextProvider();

  return useRoutes([
    // Main Routes
    {
      path: "/",
      element: <MainLayout />,
      children: [
        { path: "/", element: <LandingPage /> },
        { path: "/about", element: <About /> },
      ],
    },
    {
      path: "/",
      element: <MainLayout />,
      children: [
        { path: "/", element: <LandingPage /> },
        // { path: "/privacy", element: <Privacy /> },
      ],
    },
    {
      path: "/",
      element: <MainLayout />,
      children: [
        { path: "/", element: <LandingPage /> },
        { path: "/pricing", element: <Pricing /> },
      ],
    },
    {
      path: "/",
      element: <MainLayout />,
      children: [
        { path: "/", element: <LandingPage /> },
        // { path: "/termsconditions", element: <Terms /> },
      ],
    },
    {
      path: "*",
      element: <LogoOnlyLayout />,
      children: [
        { path: "404", element: <NotFound /> },
        { path: "*", element: <Navigate to="/404" replace /> },
      ],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
    // Authentication routes
    {
      path: "auth",
      children: [
        {
          path: "login",
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: "register",
          children: [
            {
              path: "/auth/register",
              element: (
                <GuestGuard>
                  <Register />
                </GuestGuard>
              ),
            },
            {
              path: "/auth/register/:id/verify",
              element: <Verify />,
            },
            {
              path: "/auth/register/setup",
              element: <Setup />,
            },
          ],
        },
        {
          path: "/auth/signin",
          element: <CognitoAuthRedirect />,
        },
        { path: "reset", element: <ResetPassword /> },
      ],
    },
    // Dashboard internal tooling routes
    {
      path: "dashboard",
      element: (
        <CognitoContext.Provider value={cognitoContext}>
          <AuthGuardRedirect>
            {/* <SubscriptionProvider /> */}
            <SubscriptionBasedGuard>
              <RoleBasedGuard>
                <DashboardLayout />
              </RoleBasedGuard>
            </SubscriptionBasedGuard>
          </AuthGuardRedirect>
        </CognitoContext.Provider>
      ),
      children: [
        { element: <Navigate to="/dashboard/offlineOrders" replace /> },
        { path: "commandcentral", element: <CommandCentral/> },
        { 
          path: "offlineorders",
          children: [
            { path: "/dashboard/offlineorders", element: <OfflineOrders /> },
            { path: "/dashboard/offlineorders/new", element: <NewOfflineDynamic /> },
            { path: "/dashboard/offlineorders/edit", element: <NewOfflineDynamic /> },
          ]
        },
        { path: "onlineorders", element: <OnlineOrders /> },
        { path: "orderstatus", element: <OrderStatus /> },
        { path: "inbox", element: <Inbox /> },
        { path: "userguide", element: <UserGuide /> },
        { path: "settings", element: <Settings /> },
        {
          path: "tools",
          children: [
            { path: "events", element: <Events /> },
            { path: "buzzwords", element: <Buzzwords /> },
            { path: "hotproducts", element: <HotProducts /> },
            { path: "pricematch", element: <Pricematch /> },
            { path: "sellgpt", element: <SellGPT /> },
          ],
        },
        {
          path: "invoice",
          children: [
            { path: "/dashboard/invoice", element: <Invoice /> },
            { path: "/dashboard/invoice/new", element: <NewInvoice /> },
            { path: "/dashboard/invoice/:id/invoiceView", element: <InvoiceDetails TYPE="generic" /> },
            { path: "/dashboard/invoice/:id/onlineView", element: <InvoiceDetails TYPE="online" /> },
            { path: "/dashboard/invoice/:id/offlineView", element: <InvoiceDetails TYPE="offline" /> },
            { path: "/dashboard/invoice/:id/procurementView", element: <InvoiceDetails TYPE="procurement" /> },
            { path: "/dashboard/invoice/:id/offlineBatchView", element: <InvoiceDetails TYPE="offline-batch" /> },
            { path: "/dashboard/invoice/:id/invoiceEdit", element: <InvoiceEdit TYPE="generic" /> },
            { path: "/dashboard/invoice/:id/onlineEdit", element: <InvoiceEdit TYPE="online" /> },
            { path: "/dashboard/invoice/:id/offlineEdit", element: <InvoiceEdit TYPE="offline" /> },
            { path: "/dashboard/invoice/:id/offlineBatchEdit", element: <InvoiceEdit TYPE="offline-batch" /> },
            { path: "/dashboard/invoice/:id/procurementEdit", element: <InvoiceEdit TYPE="procurement" /> },
          ],
        },
        {
          path: "financials",
          children: [
            { path: "cashflow", element: <Cashflow /> }, 
          ],
        },
        {
          path: "taxes",
          children: [
            { path: "/dashboard/taxes", element: <TaxArchive /> }, 
            { path: "/dashboard/taxes/:quarterId", element: <TaxArchiveQuarter /> },
            { path: "/dashboard/taxes/:quarterId/month/:monthId", element: <TaxArchiveMonth /> },
            { path: "/dashboard/taxes/:quarterId/month/:monthId/payment", element: <TaxArchivePayment /> },
            { path: "/dashboard/taxes/:quarterId/month/:monthId/expense", element: <TaxArchiveExpense /> },
            { path: "/dashboard/taxes/invoice/auth", element: <TaxInvoice /> },
            // /dashboard/taxes/:quarterId/month/:monthId/deposit/invoice/:invoiceId
            { path: "/dashboard/taxes/invoice/:id", element: <TaxArchiveInvoiceDetail /> },
            { path: "/dashboard/taxes/submitted/:quarterId/month/:monthId/:type", element: <SubmittedTaxInvoice /> },
            { path: "/dashboard/taxes/submitted/pdf/:quarterId/month/:monthId/:type", element: <SubmittedTaxInvoicePDF /> },
            { path: "/dashboard/taxes/submitted/:id/:type", element: <SubmittedTaxInvoiceDetail /> }
          ],
        },
        {
          path: "payment",
          children: [
            { path: "/dashboard/payment", element: <Payment /> },
            {
              path: "/dashboard/payment/:id/paymentView/",
              element: <PaymentDetails />,
            },
          ],
        },
        {
          path: "operations",
          children: [
            { path: "/dashboard/operations/store", element: <Store /> },
            {
              path: "/dashboard/operations/performance",
              element: <Performance />,
            },
            {
              path: "/dashboard/operations/:id/productView",
              element: <ProductDetail />,
            },
            {
              path: "/dashboard/operations/:id/productEdit",
              element: <NewProduct />,
            },
            { path: "/dashboard/operations/catalogue", element: <Catalogue /> },
            {
              path: "/dashboard/operations/catalogue/new",
              element: <NewProduct />,
            },
            {
              path: "/dashboard/operations/catalogue/new/:id",
              element: <NewProduct />,
            },
            { path: "/dashboard/operations/catalogue/edit", element: <NewProductDynamic /> },
            { path: "/dashboard/operations/procurement", element: <Procurement /> },
            { path: "/dashboard/operations/procurement/new", element: <NewProcurement /> },
            { path: "/dashboard/operations/procurement/edit/:id", element: <NewProcurement /> },

            { path: "/dashboard/operations/catalogue/create/raw-material", element: <NewRawMaterial /> },
            { path: "/dashboard/operations/catalogue/create/raw-material/:id", element: <NewRawMaterial /> },
            { path: "/dashboard/operations/catalogue/create/raw-material/:id/duplicate", element: <NewRawMaterial /> },
            { path: "/dashboard/operations/catalogue/config/raw-material", element: <RawMaterialConfig /> },

            { path: "/dashboard/operations/catalogue/create/manufactured-products", element: <NewManufacturedProduct /> },
            { path: "/dashboard/operations/catalogue/create/manufactured-products/:id/productEdit", element: <NewManufacturedProduct /> },
            { path: "/dashboard/operations/catalogue/create/manufactured-products/:id/duplicate", element: <NewManufacturedProduct /> },
            { path: "/dashboard/operations/catalogue/config/manufactured-products", element: <ManufacturedProductConfig /> },

            { path: "/dashboard/operations/inventory", element: <Inventory /> },
            {
              path: "/dashboard/operations/inventory/new",
              element: <NewWarehouse />,
            },
            {
              path: "/dashboard/operations/inventory/edit/:id",
              element: <NewWarehouse />,
            },
            { path: "/dashboard/operations/inventory/:id", element: <InventoryDetail /> },
            {
              path: "/dashboard/operations/customerdirectory",
              element: <CustomerDirectory />,
            },
            {
              path: "/dashboard/operations/customerdirectory/new",
              element: <NewCustomer />,
            },
            {
              path: "/dashboard/operations/:id/customerEdit",
              element: <NewCustomer />,
            },
            { path: "/dashboard/operations/checkout", element: <Checkout /> },
          ],
        },
        {
          path: "admin",
          children: [
            { path: "/dashboard/admin/usermanagement", element: <UserManagement /> },
            {
              path: "/dashboard/admin/usermanagement/:id/information",
              element: <UserInformationManagement />,
            },
            { path: "/dashboard/admin/rolemanagement", element: <RoleManagement /> },
            { path: "/dashboard/admin/rolemanagement/new", element: <RoleCreationManagement /> },
            { path: "/dashboard/admin/rolemanagement/:id/edit", element: <RoleCreationManagement /> },
          ],
        },
        {
          path: "subscription",
          children: [
            { path: "/dashboard/subscription/payment", element: <SubscriptionPayment /> },
            { path: "/dashboard/subscription/payment/method", element: <SubscriptionPaymentMethod /> },
            { path: "/dashboard/subscription/payment/cancel", element: <SubscriptionPaymentCancel /> },
            { path: "/dashboard/subscription/payment/cancel/confirmation", element: <SubscriptionPaymentCancelConfirmation /> },
            { path: "/dashboard/subscription/payment/success", element: <SubscriptionPaymentComplete /> },
          ]
        }
      ],
    },
  ]);
}

// Main
const LandingPage = Loadable(lazy(() => import("../pages/LandingPage")));
const NotFound = Loadable(lazy(() => import("../pages/Page404")));
const About = Loadable(lazy(() => import("../pages/About")));
const Privacy = Loadable(lazy(() => import("../pages/Privacy")));
const Terms = Loadable(lazy(() => import("../pages/Terms")));
const Pricing = Loadable(lazy(() => import("../pages/Pricing")));

// Authentication
const Login = Loadable(lazy(() => import("../pages/Login")));
const Register = Loadable(lazy(() => import("../pages/Register")));
const Verify = Loadable(lazy(() => import("../pages/Verify")));
const Setup = Loadable(lazy(() => import("../pages/Setup")));
const CognitoAuthRedirect = Loadable(lazy(() => import("../pages/CognitoAuthRedirect")));
const ResetPassword = Loadable(lazy(() => import("../pages/ResetPassword")));

// Dashboard
const OfflineOrders = Loadable(lazy(() => import("../pages/OfflineOrders")));
const NewOfflineDynamic = Loadable(lazy(() => import("../pages/NewOfflineDynamic")));
const OnlineOrders = Loadable(lazy(() => import("../pages/OnlineOrders")));
const OrderStatus = Loadable(lazy(() => import("../pages/OrderStatus")));
const Settings = Loadable(lazy(() => import("../pages/UserAccount")));
const Inbox = Loadable(lazy(() => import("../pages/Inbox")));
const UserGuide = Loadable(lazy(() => import("../pages/UserGuide")));

// Tools
const Events = Loadable(lazy(() => import("../pages/Events")));
const Buzzwords = Loadable(lazy(() => import("../pages/Buzzwords")));
const Pricematch = Loadable(lazy(() => import("../pages/Pricematch")));
const SellGPT = Loadable(lazy(() => import("../pages/SellGPT")));
const HotProducts = Loadable(lazy(() => import("../pages/HotProducts")));

//Invoicing
const NewInvoice = Loadable(lazy(() => import("../pages/NewInvoice")));
const Invoice = Loadable(lazy(() => import("../pages/Invoice")));
const InvoiceDetails = Loadable(lazy(() => import("../pages/InvoiceDetails")));
const InvoiceEdit = Loadable(lazy(() => import("../pages/InvoiceEdit")));

//Financials
const Cashflow = Loadable(lazy(() => import('../pages/Cashflow')));
const TaxArchive = Loadable(lazy(() => import('../pages/TaxArchive')));
const TaxArchiveQuarter = Loadable(lazy(() => import('../pages/TaxArchiveQuarter')));
const TaxArchiveMonth = Loadable(lazy(() => import('../pages/TaxArchiveMonth')));
const TaxArchivePayment = Loadable(lazy(() => import('../pages/TaxArchivePayment')));
const TaxArchiveExpense = Loadable(lazy(() => import('../pages/TaxArchiveExpense')));
const TaxInvoice = Loadable(lazy(() => import('../pages/TaxInvoice')));
const TaxArchiveInvoiceDetail = Loadable(lazy(() => import('../pages/TaxArchiveInvoiceDetail')));
const SubmittedTaxInvoice = Loadable(lazy(() => import('../pages/SubmittedTaxInvoice')));
const SubmittedTaxInvoicePDF = Loadable(lazy(() => import('../pages/SubmittedTaxInvoicePDF')));
const SubmittedTaxInvoiceDetail = Loadable(lazy(() => import('../pages/SubmittedTaxInvoiceDetail')));
//Payments
const Payment = Loadable(lazy(() => import("../pages/Payment")));
const PaymentDetails = Loadable(lazy(() => import("../pages/PaymentDetails")));

// Store
const Store = Loadable(lazy(() => import("../pages/Store")));
const Catalogue = Loadable(lazy(() => import("../pages/Catalogue")));
const RawMaterialConfig = Loadable(lazy(() => import("../pages/inventory-catalogue/RawMaterialConfig")));
const NewRawMaterial = Loadable(lazy(() => import("../pages/inventory-catalogue/NewRawMaterial")));
const ManufacturedProductConfig = Loadable(lazy(() => import("../pages/inventory-catalogue/ManufacturedProductConfig")));
const NewManufacturedProduct = Loadable(lazy(() => import("../pages/inventory-catalogue/NewManufacturedProduct")));
const NewProductDynamic = Loadable(lazy(() => import("../pages/NewProductDynamic")));
const Performance = Loadable(lazy(() => import("../pages/Performance")));
const CustomerDirectory = Loadable(
  lazy(() => import("../pages/CustomerDirectory"))
);
const NewCustomer = Loadable(lazy(() => import("../pages/NewCustomer")));
const NewProduct = Loadable(lazy(() => import("../pages/NewProduct")));
const ProductDetail = Loadable(
  lazy(() => import("../components/catalogue-feature/ProductDetail"))
);

// Procurement
const Procurement = Loadable(lazy(() => import("../pages/Procurement")));
const NewProcurement = Loadable(lazy(() => import("../pages/NewProcurement")));

// Inventory Management
const Inventory = Loadable(lazy(() => import("../pages/Inventory")));
const NewWarehouse = Loadable(lazy(() => import("../pages/NewWarehouse")));
const InventoryDetail = Loadable(lazy(() => import("../pages/InventoryDetail")));

// Empty
const CommandCentral = Loadable(lazy(() => import("../pages/CommandCentral")));

// admin panel
const UserManagement = Loadable(lazy(() => import("../pages/UserManagement")));
const RoleManagement = Loadable(lazy(() => import("../pages/RoleManagement")));
const UserInformationManagement = Loadable(lazy(() => import("../pages/UserInformationManagement")));
const RoleCreationManagement = Loadable(lazy(() => import("../pages/RoleCreationManagement")));

// subscription 
const SubscriptionPayment = Loadable(lazy(() => import("../pages/SubscriptionPayment")));
const SubscriptionPaymentComplete = Loadable(lazy(() => import("../pages/SubscriptionPaymentComplete")));
const SubscriptionPaymentMethod = Loadable(lazy(() => import("../pages/SubscriptionPaymentMethod")));
const SubscriptionPaymentCancel = Loadable(lazy(() => import("../pages/SubscriptionPaymentCancel")));
const SubscriptionPaymentCancelConfirmation = Loadable(lazy(() => import("../pages/SubscriptionPaymentCancelConfirmation")));

// SubscriptionPaymentCancel