import { PATH_DASHBOARD } from '../../routes/paths';
import SvgIconStyle from '../../components/SvgIconStyle';
import i18next from 'i18next'
// ----------------------------------------------------------------------
const getIcon = (name, sx) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%', ...sx }} />
);

const ICONS = {
  store: getIcon('ic_store'),
  priceMatch: getIcon('ic_priceMatch'),
  sellgpt: getIcon('ic_chat'),
  events: getIcon('ic_events'),
  user: getIcon('ic_user'),
  buzzwords: getIcon('ic_buzzwords'),
  offlineOrders: getIcon('ic_offlineOrders'),
  onlineOrders: getIcon('ic_onlineOrders'),
  manageorders: getIcon('ic_manageorders'),
  reports: getIcon('ic_reports'),
  procurement: getIcon('ic_procurement'),
  catalogue: getIcon('ic_catalogue'),
  customerDirectory: getIcon('ic_customerdirectory'),
  performance: getIcon('ic_performance'),
  invoicing: getIcon('ic_invoicing'),
  hotProducts: getIcon('ic_hotproducts'),
  mail: getIcon('ic_mail'),
  userGuide: getIcon('ic_userGuide'),
  commandCentral: getIcon('ic_commandCentral', { width: "74%", height: "74%" }),
  cashflow: getIcon('ic_cashflow'),
  payment: getIcon('ic_payment'),
  taxArchive: getIcon('ic_smartTax'),
  inventoryManagement: getIcon('ic_inventoryManagement'),
  placeholder: getIcon('ic_placeholder'),
};

const sidebarConfig = [
  {
    subheader: 'Sellpoint',
    items: [
      // Command Central
      // ----------------------------------------------------------------------
      {
        title: i18next.t('sidebar.commandCentral'),
        path: PATH_DASHBOARD.centralCommand.centralCommand,
        icon: ICONS.commandCentral,
        dropKey: 'commandCentral',
      },
      // Order Management
      // ----------------------------------------------------------------------
      {
        title: i18next.t('sidebar.orderManagement.title'),
        dropKey: 'orderManagement',
        children: [
          { title: i18next.t('sidebar.orderManagement.offlineOrders'), path: PATH_DASHBOARD.general.offlineOrders, icon: ICONS.offlineOrders, value: "offlineOrders" },
          // { title: i18next.t('sidebar.orderManagement.onlineOrders'), path: PATH_DASHBOARD.general.onlineOrders, icon: ICONS.onlineOrders, value: "onlineOrders" },
          { title: i18next.t('sidebar.orderManagement.orderStatus'), path: PATH_DASHBOARD.general.orderStatus, icon: ICONS.manageorders, value: "orderStatus" },
        ]
      },
      // Financials
      // ----------------------------------------------------------------------
      {
        title: i18next.t('sidebar.financials.title'),
        dropKey: 'financials',
        children: [
          { title: i18next.t('sidebar.financials.cashflow'), path: PATH_DASHBOARD.financials.cashflow, icon: ICONS.cashflow, value: "cashflow" },
          { title: i18next.t('sidebar.financials.invoicing'), path: PATH_DASHBOARD.invoice.invoice, icon: ICONS.invoicing, value: "invoicing" },
          { title: i18next.t('sidebar.financials.payment'), path: PATH_DASHBOARD.payment.payment, icon: ICONS.payment, value: "payment" },
          { title: i18next.t('sidebar.financials.taxArchive'), path: PATH_DASHBOARD.taxArchive.taxArchive, icon: ICONS.taxArchive, value: "taxArchive" },
        ] 
      },
      // 'Commerce'
      // ----------------------------------------------------------------------
      {
        title: i18next.t('sidebar.commerce.title'),
        dropKey: 'commerce',
        children: [
          { title: i18next.t('sidebar.commerce.performance'), path: PATH_DASHBOARD.operations.performance, icon: ICONS.performance, value:"performance" },
          // { title: i18next.t('sidebar.commerce.onlineStore'), path: PATH_DASHBOARD.operations.store, icon: ICONS.store },
          { title: i18next.t('sidebar.commerce.inventoryCatalogue'), path: PATH_DASHBOARD.operations.catalogue, icon: ICONS.catalogue, value:"catalogue" },
          { title: i18next.t('sidebar.commerce.procurement'), path: PATH_DASHBOARD.operations.procurement, icon: ICONS.procurement, value:"procurement" },
          { title: i18next.t('sidebar.commerce.inventoryManagement'), path: PATH_DASHBOARD.operations.inventory, icon: ICONS.inventoryManagement, exclude: ['s'], value:"inventoryManagement" },
          { title: i18next.t('sidebar.commerce.customerDirectory'), path: PATH_DASHBOARD.operations.customerDirectory, icon: ICONS.customerDirectory, value:"customerDirectory" },
        ]
      },
      // Marketing
      // ----------------------------------------------------------------------
      {
        title: i18next.t('sidebar.marketingTools.title'),
        dropKey: 'marketingTools',
        children: [
          { title: i18next.t('sidebar.marketingTools.events'), path: PATH_DASHBOARD.tools.events, icon: ICONS.events, value:"events" }, //exclude: ['s', 'mm'] },
          { title: i18next.t('sidebar.marketingTools.buzzwords'), path: PATH_DASHBOARD.tools.buzzwords, icon: ICONS.buzzwords, value: "buzzwords" },
          { title: i18next.t('sidebar.marketingTools.hotProducts'), path: PATH_DASHBOARD.tools.hotProducts, icon: ICONS.hotProducts, value: "hotProducts" },
          { title: i18next.t('sidebar.marketingTools.sellGpt'), path: PATH_DASHBOARD.tools.sellgpt, icon: ICONS.sellgpt, value: "sellgpt" },
          { title: i18next.t('sidebar.marketingTools.priceMatch'), path: PATH_DASHBOARD.tools.priceMatch, icon: ICONS.priceMatch, value: "priceMatch" },
        ]
      },
      // Messaging
      // ----------------------------------------------------------------------
      {
        title: i18next.t('sidebar.support.title'), // 'Support',
        dropKey: 'support',
        children: [
          { title: i18next.t('sidebar.support.userGuide'), path: PATH_DASHBOARD.general.userGuide, icon: ICONS.userGuide },
          { title: i18next.t('sidebar.support.contactUs'), path: PATH_DASHBOARD.general.inbox, icon: ICONS.mail }
        ]
      }
    ]
  }
];

export default sidebarConfig;
